
import {
    FILTER_DATA,
    INITIAL_PRINTING_ORDER_LIST
} from "../actions/types";

const initialState = {
    orders: [],
    orderFilters: [],
};

const filterAllData = orders => {
    return {
        orders: orders
            .sort(function (a, b) {
                const nameA = a.requestID.toUpperCase(); // ignore upper and lowercase
                const nameB = b.requestID.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            .slice(0, 100),
    };
};

const printingOrderListReducer = (state = initialState, action) => {
    //console.log(action.type);
    switch (action.type) {
        case FILTER_DATA:
            return {
                ...state,
                orders: action.payload.orders
            };
        case INITIAL_PRINTING_ORDER_LIST:
            const {orders} = filterAllData(
                action.payload.orders
            );
            return {
                ...state,
                orders: orders,
                orderFilters: action.payload.orderFilters
            };

        default:
            return state;
    }
};

export default printingOrderListReducer;
