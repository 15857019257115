// jobPrintingReducer.js

import {
    FILTER_DATA,
    INITIAL_JOB_ORDER_PRINTING_LOG
} from "../actions/types";

const initialState = {
    logs: [],
};

const filterAllData = logs => {
    return {
        logs: logs
            .sort(function (a, b) {
                const nameA = a.orderCode.toUpperCase(); // ignore upper and lowercase
                const nameB = b.orderCode.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            .slice(0, 100),
    };
};

const jobPrintingReducer = (state = initialState, action) => {
    //console.log(action.type);
    switch (action.type) {
        case FILTER_DATA:
            return {
                ...state,
                logs: action.payload.logs
            };
        case INITIAL_JOB_ORDER_PRINTING_LOG:
            const {logs} = filterAllData(
                action.payload.logs
            );
            return {
                ...state,
                logs: logs,
            };

        default:
            return state;
    }
};

export default jobPrintingReducer;
