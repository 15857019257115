import React, { Component } from "react";
import { Table, Container } from "reactstrap";
import { checkTokenExpire } from '../services/userService';

class Home extends Component {
  constructor() {
    super();
    this.state = {
    };
  }


  async componentDidMount() {
    this.props.history.push('/merchant');
    //checkTokenExpire(() => this.props.history.push('/login'));
  }
  componentWillUnmount() {
  }

  render() {
    return (
      <Container>
        <h2 className="h2Class">Home Page</h2>
      </Container>
    );
  }
}


export default Home;
