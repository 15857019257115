export const apiKey = 'AIzaSyAryT2WhXYAi3wk93uChPHLkMKiQ19QK70'; //Staging
export const authDomain = 'getz-staging-monitor.firebaseapp.com'; //Staging
export const databaseUrl = 'https://getz-staging-monitor.firebaseio.com/'; //Staging
export const storeageBucket = 'getz-staging-monitor.appspot.com'; //Staging
export const getzAPI = 'https://sit.api-gateway.ms1.getz.co/net'; //Staging

// export const apiKey = 'AIzaSyCFcwr8xTzMpOPAdkURCGbt1DFkimSuhQI'; //Production
// export const authDomain = 'getz-fb.firebaseapp.com'; //Production
// export const databaseUrl = 'https://getz-fb.firebaseio.com'; //Production
// export const storeageBucket = 'getz-fb.appspot.com'; //Production
// export const getzAPI = 'https://live.api-gateway.ms1.getz.co/net'; //Production

export const accessToken = 'access_token';