// dashboardReducer.js

import {
  ADD_PLACE,
  ADD_ORDER,
  ADD_PRODUCT,
  ADD_PROMOTION,
  INITIAL_ORDER_DATA,
  FILTER_DATA
} from "../actions/types";

const initialState = {
  orderData: [],
  productData: [],
  promotionData: [],
  filterData: []
};

const filterAllData = orders => {
  return {
    orders: orders.sort(function(a, b) {
      return new Date(b.modifiedDate) - new Date(a.modifiedDate);
    }).slice(0, 50),
  };
};

const dashboardReducer = (state = initialState, action) => {
  //console.log(action.type);
  switch (action.type) {
    case FILTER_DATA:
      return {
        ...state,
        filterData: action.payload.orders
      };
    case INITIAL_ORDER_DATA:
      const { orders } = filterAllData(
        action.payload.orders
      )
      return {
        ...state,
        orderData: orders
      };
    case ADD_ORDER:
      return {
        ...state,
        orderData: action.payload.orders
      };
    default:
      return state;
  }
};

export default dashboardReducer;
