import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import Cookies from 'js-cookie';

import { getzAPI, accessToken } from '../env';

const baseUrl = getzAPI;//'http://localhost/Getz.API';
const currentToken = Cookies.get(accessToken);

export const getListMerchant = (onHistory) => {
    if (currentToken) {
        const requestOptions = { method: 'GET', headers: authHeader(currentToken) };

        return new Promise((resolve, reject) => {
            return fetch(`${baseUrl}/api/merchantPanel/getMerchants`, requestOptions)
                .then(handleResponse)
                .then(data => {
                    if (data.Status === 1) {
                        return resolve(data.Result);
                    }
                    else {
                        return reject({ status: 400 });
                    }
                })
                .catch(err => {
                    console.log(err);
                });

        });
    }
    else {
        onHistory();
        return false;
    }
}

export const getListOutlet = (onHistory, bucket) => {
    if (currentToken) {
        const requestOptions = { method: 'GET', headers: authHeader(currentToken) };
        return new Promise((resolve, reject) => {
            return fetch(`${baseUrl}/api/merchantPanel/getOutlets/${bucket}`, requestOptions)
                .then(handleResponse)
                .then(data => {
                    if (data.Status === 1) {
                        return resolve(data.Result);
                    }
                    else {
                        return reject({ status: 400 });
                    }
                })
                .catch(err => {
                    console.log(err);
                });

        });
    }
    else {
        onHistory();
        return false;
    }
}

export const getListStation = (onHistory, bucket, branchIndex) => {
    if (currentToken) {
        const requestOptions = { method: 'GET', headers: authHeader(currentToken) };

        return new Promise((resolve, reject) => {
            return fetch(`${baseUrl}/api/merchantPanel/getStations/${bucket}/${branchIndex}`, requestOptions)
                .then(handleResponse)
                .then(data => {
                    if (data.Status === 1) {
                        return resolve(data.Result);
                    }
                    else {
                        return reject({ status: 400 });
                    }
                })
                .catch(err => {
                    console.log(err);
                });

        });
    }
    else {
        onHistory();
        return false;
    }
}

export const getListPrimaryDomain = (onHistory) => {
    if (currentToken) {
        const requestOptions = { method: 'GET', headers: authHeader(currentToken) };

        return new Promise((resolve, reject) => {
            return fetch(`${baseUrl}/api/merchantPanel/getPrimaryDomains`, requestOptions)
                .then(handleResponse)
                .then(data => {
                    if (data.Status === 1) {
                        return resolve(data.Result);
                    }
                    else {
                        return reject({ status: 400 });
                    }
                })
                .catch(err => {
                    console.log(err);
                });

        });
    }
    else {
        onHistory();
        return false;
    }
}

export const revertStation = (data, onHistory) => {
    if (currentToken) {
        const requestOptions = {
            method: 'POST',
            Authorization: `Bearer ${accessToken}`,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(`${baseUrl}/api/merchantPanel/revertStation`, requestOptions);
                return resolve(response.ok);
                
            } catch (err) {
                console.log('err', err );
            }
        });
    }
    else {
        onHistory();
        return false;
    }
}