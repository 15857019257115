import { ADD_ORDER, INITIAL_ORDER_DATA, FILTER_DATA } from './types';

export const addOrder = order => {
  return {
    type: ADD_ORDER,
    payload: {order}
  }
}

export const initialDataOrder = orders => {
  return {
    type: INITIAL_ORDER_DATA,
    payload: {orders}
  }
}

export const filterOrderData = orders => {
  return {
    type: FILTER_DATA,
    payload: {orders}
  }
}
