import React, { Component } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { initialMenuAvailability } from "../actions/menuAvailability";
import { checkTokenExpire } from '../services/userService';
import { reflectOnlineStore } from '../services/menuAvailabilityService';
import { renderTablePage, fetchDataFromFireBase, finddata, offDataFromFireBase, updateDataFromFireBase, onDataFromFireBase } from "../helpers/userControlMA";

class MenuAvailabilityToday extends Component {
	constructor(props) {
		super(props);

		let currentDate = dateFormat(new Date(), "yyyy-mm-dd");
		if(this.props.location.state && this.props.location.state.currentDate) {
			currentDate = this.props.location.state.currentDate;
			localStorage.setItem('date', dateFormat(currentDate, "yyyymmdd"));
		}
		else {
			localStorage.setItem('date', dateFormat(new Date(), "yyyymmdd"));
		}

		this.state = {
			data: [],
			dataFilter: [],
			ref: "ma-today/" + dateFormat(currentDate, "yyyymmdd"),
			searchMA: '',
			selectStatus: '',
			selectOutlet: '',
			currentDate: currentDate,
		};
	}

	async componentDidMount() {
		checkTokenExpire(() => this.props.history.push('/login'));

		let value = await fetchDataFromFireBase("value", this.state.ref);
		this.initMenuAvailability(value, value);

		onDataFromFireBase("value", this.state.ref, this.initMenuAvailability);
	}

	componentWillUnmount() {
		offDataFromFireBase("value", this.state.ref);
	}

	initMenuAvailability = (data, dataFilter) => {
		this.props.initialMenuAvailabilityToDay(data, dataFilter);
	}

	handleInputChange = async e => {
		const { name, value } = e.target;

		switch (name) {
			case 'searchMA':
				this.setState({ searchMA: value });
				break;

			case 'selectDate':
				const newRef = "ma-today/" + dateFormat(value, "yyyymmdd");

				localStorage.setItem('date', dateFormat(value, "yyyymmdd"));
				this.setState({
					ref: newRef,
					currentDate: dateFormat(value, "yyyy-mm-dd"),
				});

				const dataFetch = await fetchDataFromFireBase("value", newRef);
				this.initMenuAvailability(dataFetch, dataFetch);
				break;
			case 'selectStatus':
				this.setState({
					selectStatus: value
				});
				const dataStatus = await finddata(this.props.data, this.state.searchMA, value, '');
				this.initMenuAvailability(this.props.data, dataStatus);
				break;
			case 'selectOutlet':
				this.setState(
					{ selectOutlet: value }
				);
				console.log(value);
				const dataOutlet = await finddata(this.props.data, this.state.searchMA, this.state.selectStatus, value);
				this.initMenuAvailability(this.props.data, dataOutlet);
				break;

			default:
				break;
		}
	}

	handleSubmit = async e => {
		e.preventDefault();

		const dataFound = await finddata(this.props.data, this.state.searchMA, this.state.selectStatus, this.state.selectOutlet);
		this.initMenuAvailability(this.props.data, dataFound);
	}

	handleClick = requestId => {
		const obj = this.props.dataFilter.find(d => d.id === requestId);
		const onSuccess = () => {
			obj.status = 1;
			updateDataFromFireBase(obj, this.state.ref);

			// this.props.data.map(x => x.id === obj.id ? obj : x)
			// this.props.dataFilter.map(x => x.id === obj.id ? obj : x)
			this.initMenuAvailability(this.props.data, this.props.dataFilter);
			//window.alert('Update Successed!');
		}

		const onFail = () => {
			window.alert('Update Failed!');
		}

		reflectOnlineStore(obj, onSuccess, onFail);
	}

	handleDetailClick = (id) => {
		this.props.history.push(`/menuAvailabilityDetail/ma-today/${id}`);
	}

	render() {
		return (
			<Container className="themed-container pl-5 pr-5" fluid={true}>
				<h2 className="h2Class">Menu Availability Today</h2>
				<div className="mb-3">
					<div>
						{renderTablePage(this.props.dataFilter, this.handleSubmit, this.handleClick, this.handleInputChange, this.handleDetailClick, this.state.currentDate, this.props.data)}
					</div>
				</div>
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		data: state.menuAvailabilityStore.data,
		dataFilter: state.menuAvailabilityStore.dataFilter,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initialMenuAvailabilityToDay: (data, dataFilter) => {
			dispatch(initialMenuAvailability(data, dataFilter));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuAvailabilityToday);