import React, { Component } from "react";
import { Button, Table, Container, Form, FormGroup, Label, Input, Row, Col, InputGroup, InputGroupAddon } from "reactstrap";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { database } from "../db/firebase";
import { initialPrintingOrderList } from "../actions/printingOrderList";
import { getOrderType, getBadge } from "../helpers/ListLogs";
import { showDateTimeWithTimezone } from "../helpers/utils";
import { checkTokenExpire } from '../services/userService';
import DataTable from 'react-data-table-component';
import { removeVietnameseTones } from "../helpers/utils";

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
}

class PrintingOrders extends Component {
    constructor() {
        super();
        const currentDate = localStorage.getItem('currentDate') === undefined ? new Date() : JSON.parse(localStorage.getItem('currentDate'));
        const selectOutlet = localStorage.getItem('selectOutlet') === undefined ? '' : JSON.parse(localStorage.getItem('selectOutlet'));
        const searchOrder = localStorage.getItem('searchOrder') === undefined ? '' : JSON.parse(localStorage.getItem('searchOrder'));

        this.state = {
            orders: [],
            ref: "order-print-logs-list/" + dateFormat(currentDate, "yyyymmdd"),
            orderFilters: [],
            selectOutlet: selectOutlet,
            searchOrder: searchOrder,
            orderDate: dateFormat(currentDate, "yyyy-mm-dd")
            // this is where we are connecting to with sockets,
        };
    }

    async componentDidMount() {
        console.log('componentDidMount');
        checkTokenExpire(() => this.props.history.push('/login'));

        const state_current = this;

        let orders = await this.fetchDataFromFireBase("value", this.state.ref);

        this.initPrintingOrder(orders);
        let ref = database.ref(this.state.ref);
        database.ref(ref).on("value", snapshot => {
            if (snapshot.val() && this.state.orderDate === dateFormat(new Date(), "yyyy-mm-dd")) {
                let data = snapshot.val();
                let orders = Object.values(data);
                this.initPrintingOrder(orders);
            }
        });
    }

    componentWillUnmount() {
        database.ref(this.state.ref).off("value");
    }


    async fetchDataFromFireBase(e, ref) {
        let snapshot = await database.ref(ref).once(e);
        let data = snapshot.val();
        if (data) {
            let items = Object.values(data);
            items.filter(o => o.orderID !== undefined && o.appType === 2 && o.orderType === 2);
            return items;
        } else {
            return [];
        }
    };

    initPrintingOrder = orders => {
        const orderFound = this.findOrders(orders);
        this.props.initialPrintingOrderList(orders, orderFound);
    }

    findOrders = (orders) => {
        let orderFound = orders.filter(o => o.orderID !== undefined && o.appType === 2 && o.orderType === 2);

        if (this.state.selectOutlet && this.state.selectOutlet.length > 0) {
            orderFound = orderFound.filter(x => x.storeID === this.state.selectOutlet);
        }

        if (this.state.searchOrder && this.state.searchOrder.length > 0) {
            let textSerchOrder = removeVietnameseTones(this.state.searchOrder);
            orderFound = orderFound.filter(x => x.orderCode.match(this.state.searchOrder) || removeVietnameseTones(x.storeName).match(textSerchOrder));
        }

        return orderFound;
    }

    handleInputChange = async e => {
        const { name, value } = e.target;

        switch (name) {
            case 'searchOrder':
                this.setState({ searchOrder: value });
                localStorage.setItem('searchOrder', JSON.stringify(value));
                break;

            case 'selectOutlet':
                this.setState({ selectOutlet: value });
                localStorage.setItem('selectOutlet', JSON.stringify(value));
                break;

            case 'orderDate':
                this.setState({
                    ref: "order-print-logs-list/" + dateFormat(value, "yyyymmdd"),
                    orderDate: value,
                    selectOutlet: ''
                });

                let orders = await this.fetchDataFromFireBase("value", "order-print-logs-list/" + dateFormat(value, "yyyymmdd"));
                this.props.initialPrintingOrderList(orders, orders);
                localStorage.setItem('currentDate', JSON.stringify(value));
                break;

            default:
                break;
        }
    }

    handleSubmit = async e => {
        e.preventDefault();

        const orderFound = await this.findOrders(this.props.orders);
        this.props.initialPrintingOrderList(this.props.orders, orderFound);
    }

    handleClickReset = () => {
        localStorage.removeItem("currentDate");
        localStorage.removeItem("selectOutlet");
        localStorage.removeItem("searchOrder");
        localStorage.removeItem("orderID");
        localStorage.removeItem("requestID");

        window.location.reload(false);
    }

    render() {
        return (
            <Container>
                <h2 className="h2Class">POS Printing Orders</h2>
                <div className="mb-3">
                    <Form body="true" autoComplete="off" onSubmit={this.handleSubmit}>
                        <Row form>
                            <Col sm="12" md={{ size: 2, offset: 1 }}>
                                <FormGroup>
                                    <Label for="orderDate" hidden>Date</Label>
                                    <Input
                                        type="date"
                                        name="orderDate"
                                        id="orderDate"
                                        defaultValue={this.state.orderDate}
                                        placeholder="choose date"
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="3">
                                <FormGroup>
                                    <Label
                                        for="selectOutlet"
                                        hidden
                                    >Select</Label>

                                    {this.renderSelects(this.props.orders, this.state.selectOutlet)}
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="4">
                                <InputGroup>
                                    <Input
                                        name="searchOrder"
                                        placeholder="Search..."
                                        onChange={this.handleInputChange}
                                        defaultValue={this.state.searchOrder}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button color="success" type="submit">Search</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col sm="6" md="1">
                                <InputGroup>
                                    <Button color="danger" type="button" onClick={this.handleClickReset}>Reset</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div>
                    {this.renderTablePage(this.props.orderFilters)}
                </div>
            </Container>
        );
    }

    handleClick(orderID) {
        localStorage.setItem('orderID', JSON.stringify(orderID));
        this.props.history.push({
            //pathname: "/jobOrderPrintingLogs",
            pathname: `/jobPOSOrderPrintingLogs/${orderID}`,
            state: { orderID: orderID },
        });
    }

    renderRows(orders) {
        return orders.map(order => {
            if (order.orderID && order.isPOS === "1") {
                return (
                    <tr key={order.orderID}>
                        <td className="h3Class">{showDateTimeWithTimezone(order.modifiedDate)} </td>
                        <td className="h3Class">{order.orderCode} </td>
                        <td className="h3Class">{order.storeName} </td>
                        <td className="h3Class">{getOrderType(parseInt(order.orderType))} </td>
                        <td className="h3Class">{getBadge(parseInt(order.orderStatus))} </td>
                        <td className="h3Class">{order.isPOS} </td>
                        <td className="h3Class">
                            <Button color='info' size="sm" onClick={() => this.handleClick(order.orderID)}>See Logs</Button>
                        </td>
                    </tr>
                );
            }
            // else {
            //     return '';
            // }
        });
    }

    renderOptions(orders) {
        if (orders && orders.length > 0) {
            console.log(Array.from(new Set(orders.map(x => x.storeID))));
            const outlets = Array.from(new Set(orders.map(x => x.storeID)))
                .map(storeID => {
                    return {
                        storeID: storeID,
                        storeName: orders.find(s => s.storeID === storeID).storeName
                    };
                })
                .filter(o => o.storeID !== undefined);

            return outlets.map(outlet => {
                if (outlet.storeID) {
                    return (
                        <option key={outlet.storeID} value={outlet.storeID}>{outlet.storeName}</option>
                    );
                }
                // else {
                //     return '';
                // }
            });
        }
    }

    renderSelects(orders, selectOutlet) {
        if (selectOutlet && selectOutlet.length > 0) {
            return (
                <Input
                    type="select"
                    name="selectOutlet"
                    id="selectOutlet"
                    onChange={this.handleInputChange}
                    value={selectOutlet}
                >
                    <option value="">Select Outlet</option>
                    {this.renderOptions(orders)}
                </Input>
            )
        }
        else {
            return (
                <Input
                    type="select"
                    name="selectOutlet"
                    id="selectOutlet"
                    onChange={this.handleInputChange}
                    defaultValue={selectOutlet}
                >
                    <option value="">Select Outlet</option>
                    {this.renderOptions(orders)}
                </Input>
            )
        }
    }

    renderTablePage(orders) {
        console.log(orders);
        const data = Array.from(new Set(orders))
            .map(order => {
                return {
                    orderID: order.orderID,
                    orderCode: order.orderCode,
                    storeName: order.storeName,
                    orderType: getOrderType(parseInt(order.orderType)),
                    orderStatus: parseInt(order.orderStatus),
                    modifiedDate: showDateTimeWithTimezone(order.modifiedDate)
                };
            });
        data.sort((a, b) => (a.modifiedDate < b.modifiedDate) ? 1 : ((b.modifiedDate < a.modifiedDate) ? -1 : 0));

        const columns = [
            {
                name: 'Modified Time',
                selector: 'modifiedDate',
                sortable: true,
                center: true,
            },
            {
                name: 'Order Code',
                selector: 'orderCode',
                sortable: true,
                center: true,

            },
            {
                name: 'Outlet',
                selector: 'storeName',
                sortable: true,
                center: true,

            },
            {
                name: 'Order Type',
                selector: 'orderType',
                sortable: true,
                center: true,
            },
            {
                name: 'Order Status',
                selector: 'orderStatus',
                sortable: true,
                center: true,
                cell: row => getBadge(parseInt(row.orderStatus))
            },
            {
                name: 'Action',
                center: true,
                cell: row => <Button color='info' size="sm" onClick={() => this.handleClick(row.orderID)}>See Logs</Button>,
                button: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '72px',
                    fontSize: '16px',
                    backgroundColor: '#f6f6f6',
                }
            },
            headCells: {
                style: {
                    fontSize: '16px',
                    background: '#6c757d',
                    color: '#ffffff',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                },
            },
        };

        return (
            <DataTable
                data={data}
                columns={columns}
                pagination={true}
                customStyles={customStyles}
                keyField='orderID'
                highlightOnHover={true}
                noHeader={true}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        orders: state.printingOrderList.orders,
        orderFilters: state.printingOrderList.orderFilters,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initialPrintingOrderList: (orders, orderFilters) => {
            dispatch(initialPrintingOrderList(orders, orderFilters));
        },
    };
};

const PrintingOrdersRx = connect(mapStateToProps, mapDispatchToProps)(
    PrintingOrders
);

export default PrintingOrdersRx;
