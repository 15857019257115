
const authHeader = accessToken => {
    if (accessToken) {
        return { Authorization: `Bearer ${accessToken}` };
    } else {
        return {};
    }
    
}

export default authHeader;
