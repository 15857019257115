import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import Cookies from 'js-cookie';

import {getzAPI}  from '../env';

const baseUrl = getzAPI;
const accessToken = 'access_token';

export const login = (data, onSuccess, onFail) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    
    return fetch(`${baseUrl}/api/auth/postAuth`, requestOptions)
        .then(handleResponse)
        .then(dataAuth => {
            
            if(dataAuth.message === "Success" && dataAuth.status === 1 ) {
                Cookies.set(accessToken, dataAuth.result.token);
                
                onSuccess();
            }
            else {
                onFail();
            }

            // setTimeout(function(){
            //     resetAuthencation();
            // }, 5000);
            return dataAuth;
        })
        .catch(err => console.log(err));
}


export const logout = (onLoadLogin) => {
    Cookies.remove(accessToken);

    localStorage.removeItem("currentDate");
    localStorage.removeItem("selectOutlet");
    localStorage.removeItem("searchOrder");

    onLoadLogin();
}

export const checkTokenExpire = (onHistory, isLogin = false) => {
    const currentToken = Cookies.get(accessToken);
    if(currentToken) {
        const requestOptions = { method: 'GET', headers: authHeader(currentToken) };

        return fetch(`${baseUrl}/api/auth/getAuth`, requestOptions)
            .then(handleResponse)
            .then(dataAuth => {
                if(dataAuth.message === "Success" && dataAuth.status === 1 ){
                    Cookies.set(accessToken, dataAuth.result.token);
                    if(isLogin){
                        onHistory();
                    }
                    return true;
                }
                else {
                    if(!isLogin){
                        onHistory();
                        Cookies.remove(accessToken);
                    }
                    return false;
                }
            })
            .catch(err => {
                console.log(err);
                Cookies.remove(accessToken);
            });
    }
    else if(!isLogin){
        onHistory();
        return false;
    }
}

