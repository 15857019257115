import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Button, Form, InputGroup, Input, InputGroupAddon, Badge, Row, Col } from "reactstrap";
import { checkTokenExpire } from '../../services/userService';
import { removeVietnameseTones } from "../../helpers/utils";
import dateFormat from "dateformat";
import SyncSharpIcon from '@material-ui/icons/SyncSharp';
import { ToastContainer, toast } from 'react-toastify';
import { database } from "../../db/firebase";

import 'react-toastify/dist/ReactToastify.css';

const columns = [
    {
        name: 'Order Code'
    },
    {
        name: 'Order Code',
        width: '280px'
    },
    {
        name: 'Merchant'
    },
    {
        name: 'Outlet'
    },
    {
        name: 'Time to Fulfillment',
        width: '200px'
    },
    {
        name: 'DeliveryId',
        selector: 'deliveryId',
        center: true,
        width: '300px',
        cell: row => <div title={row.deliveryId}>{row.deliveryId}
            <Badge color={row.isAutoAssignment ? "success" : ""} className="ml-1 mr-1"> {row.isAutoAssignment ? `Auto` : ""}</Badge>
            <Badge color={row.autoReassignDeliveryNumber > 0 ? "warning" : ""}>{row.autoReassignDeliveryNumber > 0 ? row.autoReassignDeliveryNumber : ""}</Badge>
            <input type="hidden" name="deliveryOperatorId" value={row.deliveryOperatorId || ''} />
        </div>,
    },
    {
        name: 'Partner',
        selector: 'deliveryPartner',
        center: true,
        width: '100px',
        cell: row => {
            if (row.deliveryPartner) {
                let statusColor = '#be7959';
                let arrPartner = row.deliveryPartner.split('|');

                if (arrPartner[0].toLowerCase() === 'lalamove') {
                    statusColor = '#E16D38';
                }
                else if (arrPartner[0].toLowerCase() === 'grab') {
                    statusColor = '#1AAE48';
                }
                else if (arrPartner[0].toLowerCase() === 'gogox') {
                    statusColor = '#3887BA';
                }
                else if (arrPartner[0].toLowerCase() === 'milkrun') {
                    statusColor = '#265BFB';
                }
                else if (arrPartner[0].toLowerCase() === 'pandago') {
                    statusColor = '#D71A65';
                }
                else {
                    statusColor = '#be7959';
                }

                if (arrPartner.length > 1) {

                }

                return (
                    <div>
                        <Badge style={{ backgroundColor: statusColor }}>{arrPartner[0]}</Badge><br />
                        {arrPartner.length > 1 && <Badge style={{ backgroundColor: "#dfa4b8" }}>{arrPartner[1]}</Badge>}

                    </div>
                )
            }
            else {
                return (
                    <Badge> - </Badge>
                )
            }
        },
    },
    {
        name: 'Vehicle',
        selector: 'vehicle',
        center: true,
        width: '140px',
        cell: row => {
            if (row.vehicle) {
                let txtVehicle = row.vehicle.toLowerCase();
                if (txtVehicle === 'anyvehicle') {
                    txtVehicle = 'Any Vehicle';
                }

                return (
                    <div style={{ textTransform: "capitalize" }} title={txtVehicle}>{txtVehicle}</div>
                )
            }
            else {
                return (
                    <div> - </div>
                )
            }
        },
    },
    {
        name: 'Status',
        selector: 'status',
        center: true,
        width: '100px',
        cell: row => {
            let statusMessage = '';
            let statusColor = '';
            if (row.status === 2) {
                statusMessage = 'Fail';
                statusColor = '#D90000';
            }
            else if (row.status === 1) {
                if (row.deliveryStatus === 1) {
                    statusMessage = 'Pending';
                    statusColor = '#FF9326';
                }
                else if (row.deliveryStatus === 2) {
                    statusMessage = 'Assigned';
                    statusColor = '#4BBE23';
                }
                else if (row.deliveryStatus === 3) {
                    statusMessage = 'Completed';
                    statusColor = '#8663C4';
                }
                else if (row.deliveryStatus === 4) {
                    statusMessage = 'Cancelled';
                    statusColor = '#D90000';
                }
                else if (row.deliveryStatus === 5) {
                    statusMessage = 'Rejected';
                    statusColor = '#D90000';
                }
                else if (row.deliveryStatus === 6) {
                    statusMessage = 'Expired';
                    statusColor = '#D90000';
                }
                else if (row.deliveryStatus === 7) {
                    statusMessage = 'Picked up';
                    statusColor = '#23BEAB';
                }
                else if (row.deliveryStatus === 13) {
                    statusMessage = 'Returned';
                    statusColor = '#5383FF';
                }
                else {
                    statusMessage = '-';
                    statusColor = '-';
                }

            }
            return (
                <Badge style={{ backgroundColor: statusColor }} data-status={row.deliveryStatus}>{statusMessage}</Badge>
            )
        },
    },
    {
        name: 'Created date & time',
        selector: 'createdDate',
        center: true,
        width: '150px',
        cell: row => <div title={row.createdDate}>{row.createdDate}</div>,
    },
    {
        name: 'Pickup Time',
        selector: 'pickup_time',
        center: true,
        width: '150px',
        cell: row => <div title={row.pickup_time}>{row.pickup_time}</div>,
    },
];

const columnsOrder = [
    {
        name: 'Order Code',
        selector: 'orderCode',
        sortable: true,
        width: '280px',
        cell: row => <div title={row.orderCode} style={{ textAlign: 'left' }}>#{row.orderCode}
            <Badge color={row.isAutoAssignment ? "success" : ""} className="ml-1 mr-1">{row.isAutoAssignment ? `Auto` : ""}</Badge>
            <Badge color={row.autoReassignDeliveryNumber > 0 ? "warning" : ""}>{row.autoReassignDeliveryNumber > 0 ? row.autoReassignDeliveryNumber : ""}</Badge>
            <input type='hidden' name='bucket' value={row.bucket} />
            <input type='hidden' name='order_id' value={row.order_id} />
            <input type='hidden' name='deliveryOperatorId' value={row.deliveryOperatorId || ''} />
        </div>,
    },
    {
        name: 'Merchant',
        //sortable: true,
        selector: 'businessName',
        center: true,
        cell: row => <div title={row.businessName}>{row.businessName}</div>,
    },
    {
        name: 'Outlet',
        //sortable: true,
        selector: 'outletName',
        center: true,
        cell: row => <div title={row.outletName}>{row.outletName}</div>,
    },
    {
        name: <div style={{ textAlign: 'center' }}>Time to Fulfillment</div>,
        selector: 'createdDate',
        center: true,
        width: '200px',
        cell: row => {
            if (row.deliveryStartTime) {
                let currentDate = new Date(row.deliveryStartTime);//currentDate.setHours(currentDate.getHours() + 1)
                let dateNumber = Date.parse(currentDate) - Date.parse(new Date());
                if (dateNumber < 0) {
                    return <div title={row.deliveryStartTime} style={{ color: 'red' }}>0 minute</div>
                }
                else {
                    let delta = Math.abs(dateNumber) / 1000;

                    let days = Math.floor(delta / 86400);
                    delta -= days * 86400;

                    let hours = Math.floor(delta / 3600) % 24;
                    delta -= hours * 3600;

                    let minutes = Math.floor(delta / 60) % 60;
                    //delta -= minutes * 60;//second
                    if (days > 0) {
                        return <div title={row.deliveryStartTime}>{days} day: {hours} hour <br /> {minutes} minute</div>
                    }
                    else if (hours > 0) {
                        return <div title={row.deliveryStartTime}>{hours} hour: {minutes} minute</div>
                    }
                    else {
                        return <div title={row.deliveryStartTime}>{minutes} minute</div>
                    }
                }
            }
            return;
        },
    },
    {
        name: 'DeliveryId',
        selector: 'deliveryId',
        center: true,
        width: '300px',
        cell: row => <div title={row.deliveryId}>{row.deliveryId}
            <input type="hidden" name="deliveryOperatorId" value={row.deliveryOperatorId || ''} />
        </div>,
    },
    {
        name: 'Partner',
        selector: 'deliveryPartner',
        center: true,
        width: '100px',
        cell: row => {
            if (row.deliveryPartner) {
                let statusColor = '#be7959';
                let arrPartner = row.deliveryPartner.split('|');

                if (arrPartner[0].toLowerCase() === 'lalamove') {
                    statusColor = '#E16D38';
                }
                else if (arrPartner[0].toLowerCase() === 'grab') {
                    statusColor = '#1AAE48';
                }
                else if (arrPartner[0].toLowerCase() === 'gogox') {
                    statusColor = '#3887BA';
                }
                else if (arrPartner[0].toLowerCase() === 'milkrun') {
                    statusColor = '#265BFB';
                }
                else if (arrPartner[0].toLowerCase() === 'pandago') {
                    statusColor = '#D71A65';
                }
                else {
                    statusColor = '#be7959';
                }

                if (arrPartner.length > 1) {

                }

                return (
                    <div>
                        <Badge style={{ backgroundColor: statusColor }}>{arrPartner[0]}</Badge><br />
                        {arrPartner.length > 1 && <Badge style={{ backgroundColor: "#dfa4b8" }}>{arrPartner[1]}</Badge>}

                    </div>
                )
            }
            else {
                return (
                    <Badge> - </Badge>
                )
            }
        },
    },
    {
        name: 'Vehicle',
        selector: 'vehicle',
        center: true,
        width: '140px',
        cell: row => {
            if (row.vehicle) {
                let txtVehicle = row.vehicle.toLowerCase();
                if (txtVehicle === 'anyvehicle') {
                    txtVehicle = 'Any Vehicle';
                }

                return (
                    <div style={{ textTransform: "capitalize" }} title={txtVehicle}>{txtVehicle}</div>
                )
            }
            else {
                return (
                    <div> - </div>
                )
            }
        },
    },
    {
        name: 'Status',
        selector: 'status',
        center: true,
        width: '100px',
        cell: row => {
            let statusMessage = '';
            let statusColor = '';
            if (row.status === 2) {
                statusMessage = 'Fail';
                statusColor = '#D90000';
            }
            else if (row.status === 1) {
                if (row.deliveryStatus === 1) {
                    statusMessage = 'Pending';
                    statusColor = '#FF9326';
                }
                else if (row.deliveryStatus === 2) {
                    statusMessage = 'Assigned';
                    statusColor = '#4BBE23';
                }
                else if (row.deliveryStatus === 3) {
                    statusMessage = 'Completed';
                    statusColor = '#8663C4';
                }
                else if (row.deliveryStatus === 4) {
                    statusMessage = 'Cancelled';
                    statusColor = '#D90000';
                }
                else if (row.deliveryStatus === 5) {
                    statusMessage = 'Rejected';
                    statusColor = '#D90000';
                }
                else if (row.deliveryStatus === 6) {
                    statusMessage = 'Expired';
                    statusColor = '#D90000';
                }
                else if (row.deliveryStatus === 7) {
                    statusMessage = 'Picked up';
                    statusColor = '#23BEAB';
                }
                else if (row.deliveryStatus === 13) {
                    statusMessage = 'Returned';
                    statusColor = '#5383FF';
                }
                else {
                    statusMessage = '-';
                    statusColor = '-';
                }

            }
            return (
                <Badge style={{ backgroundColor: statusColor }} data-status={row.deliveryStatus}>{statusMessage}</Badge>
            )
        },
    },
    {
        name: <div style={{ textAlign: 'center' }}>Created date & time</div>,
        sortable: true,
        selector: 'createdDate',
        center: true,
        width: '150px',
        cell: row => <div title={row.createdDate} style={{ textAlign: 'center' }}>{row.createdDate}</div>,
    },
    {
        name: 'Pickup Time',
        selector: 'pickup_time',
        center: true,
        width: '150px',
        cell: row => <div title={row.pickup_time}>{row.pickup_time}</div>,
    },
];

const customStyles = {
    rows: {
        style: {
            fontSize: '16px',
            backgroundColor: '#F2FAFF',
        }
    },
};

const customOrderStyles = {
    rows: {
        style: {
            minHeight: '72px',
            fontSize: '16px',
        }
    },
    headCells: {
        style: {
            fontSize: '16px',
            '.hheJEU div, .giwWbv div': {
                borderBottom: '2px solid #0678be'
            }
        }
    }
};

const DeliveryTracking = ({ ...props }) => {
    const [dataRoot, setDataRoot] = useState([]);
    const [dataRequest, setDataRequest] = useState([]);

    const [dataOrder, setDataOrder] = useState([]);
    const [dataOrderRequest, setDataOrderRequest] = useState([]);

    const [search, setSearch] = useState();
    const [partner, setPartner] = useState();
    const [status, setStatus] = useState();
    const [vehicle, setVehicle] = useState();
    const [date, setDate] = useState(() => {
        localStorage.setItem("isRealTime", JSON.stringify(true));
        localStorage.removeItem("currentDate");
        return dateFormat(new Date(), "yyyymmdd");
    });

    useEffect(() => {
        checkTokenExpire(() => props.history.push('../../login'));

        fetchDataOrder(`deliveryorder/${date}`);
        fetchData(`deliveryoperator/${date}`);

        onDataOrderFromFireBase("value", `deliveryorder/${date}`);
        onDataFromFireBase("value", `deliveryoperator/${date}`);
    }, []);

    const initData = result => {
        const valueRequest = Object.values(result).map(value => value);

        let value = [].concat.apply([], valueRequest.map(item => {
            return Object.keys(item).map(key => {
                return item[key];
            });
        }));

        value.sort((a, b) => (a.createdDate > b.createdDate) ? -1 : ((b.createdDate > a.createdDate) ? 1 : 0));

        setDataRoot(value);

        if (partner && partner.length > 0) {
            value = value.filter(x => (x.deliveryPartner && x.deliveryPartner.split('|')[0] === partner));
        }

        if (status && status.length > 0) {
            const stt = parseInt(status);
            value = value.filter(x => (x.deliveryStatus && x.deliveryStatus === stt));
        }

        setDataRequest(value);
    }

    const fetchDataOrder = async (r) => {
        const result = await fetchDataFromFireBase("value", r);
        const value = Object.values(result);
        value.sort((a, b) => (a.createdDate > b.createdDate) ? -1 : ((b.createdDate > a.createdDate) ? 1 : 0));

        if (value) {
            setDataOrder(value);
            setDataOrderRequest(value);
        }
    };

    const fetchData = async (r) => {
        const result = await fetchDataFromFireBase("value", r);
        if (result) {
            initData(result);
        }
    };

    const onDataOrderFromFireBase = (e, ref) => {
        const isRealTime = JSON.parse(localStorage.getItem("isRealTime"));

        if (isRealTime) {
            database.ref(ref).on(e, snapshot => {
                let currentDate = localStorage.getItem("currentDate");
                if (localStorage.getItem("currentDate") === null) {
                    currentDate = date;
                }

                if (snapshot.val() && currentDate === dateFormat(new Date(), "yyyymmdd")) {
                    const result = Object.values(snapshot.val());
                    result.sort((a, b) => (a.createdDate > b.createdDate) ? -1 : ((b.createdDate > a.createdDate) ? 1 : 0));

                    setDataOrder(result);
                    setDataOrderRequest(result);

                    fetchData(`deliveryoperator/${date}`);
                }
            });
        }
    };

    const onDataFromFireBase = (e, ref) => {
        const isRealTime = JSON.parse(localStorage.getItem("isRealTime"));

        if (isRealTime) {
            database.ref(ref).on(e, snapshot => {
                let currentDate = localStorage.getItem("currentDate");
                if (localStorage.getItem("currentDate") === null) {
                    currentDate = date;
                }

                if (snapshot.val() && currentDate === dateFormat(new Date(), "yyyymmdd")) {
                    const result = snapshot.val();
                    initData(result);
                }
            });
        }
    };

    const fetchDataFromFireBase = async (e, ref) => {
        let snapshot = await database.ref(ref).once(e);
        let data = snapshot.val();

        return data || {};
    };

    const handleFilterData = (srch, prtnr, stts, vhcl) => {
        let filterDataOrder = [...dataOrder];
        if (srch && srch.length > 0) {
            const textSearch = removeVietnameseTones(srch.toString());
            filterDataOrder = filterDataOrder.filter(x => (x.businessName && removeVietnameseTones(x.businessName).match(textSearch))
                || (x.orderCode && removeVietnameseTones(x.orderCode).match(textSearch))
                || (x.outletName && removeVietnameseTones(x.outletName).match(textSearch))
                || (x.deliveryId && removeVietnameseTones(x.deliveryId).match(textSearch))
            );
        }

        if (prtnr && prtnr.length > 0) {
            filterDataOrder = filterDataOrder.filter(x => (x.deliveryPartner && x.deliveryPartner.split('|')[0] === prtnr));
        }

        if (stts && stts.length > 0) {
            const stt = parseInt(stts);
            if (stt === 0) {
                filterDataOrder = filterDataOrder.filter(x => (x.status !== undefined && x.status === 2));
            }
            else {
                filterDataOrder = filterDataOrder.filter(x => (x.deliveryStatus !== undefined && x.deliveryStatus === stt));
            }
        }

        if (vhcl && vhcl.length > 0) {
            const res = vhcl.split(" ");
            if (res.length === 1) {
                filterDataOrder = filterDataOrder.filter(x => (x.vehicle && x.vehicle.toLowerCase() === vhcl));
            }
            else if (res.length === 2) {
                filterDataOrder = filterDataOrder
                    .filter(x => (x.vehicle && (x.vehicle.toLowerCase() === res[0] || x.vehicle.toLowerCase() === res[1])));
            }
            else {
                filterDataOrder = filterDataOrder
                    .filter(x => (
                        x.vehicle && (x.vehicle.toLowerCase() === res[0] || x.vehicle.toLowerCase() === res[1] || x.vehicle.toLowerCase() === res[2])
                    ));
            }
        }

        filterDataOrder.sort((a, b) => (a.createdDate > b.createdDate) ? -1 : ((b.createdDate > a.createdDate) ? 1 : 0));
        setDataOrderRequest(filterDataOrder);

        let filterData = [];
        dataRoot.forEach(item => {
            const value = filterDataOrder.filter(x => x.order_id && x.order_id === item.order_id);
            if (value && value.length > 0) {
                filterData.push(item);
            }
        });

        filterData.sort((a, b) => (a.createdDate > b.createdDate) ? -1 : ((b.createdDate > a.createdDate) ? 1 : 0));
        setDataRequest(filterData);
    }

    const handleInputChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case 'search':
                setSearch(value.trim());
                break;

            case 'selectPartner':
                setPartner(value.trim());
                handleFilterData(search, value.trim(), status, vehicle);
                break;

            case 'selectStatus':
                setStatus(value.trim());
                handleFilterData(search, partner, value.trim(), vehicle);
                break;

            case 'selectVehicle':
                setVehicle(value.trim());
                handleFilterData(search, partner, status, value.trim());
                break;

            case 'selectDate':
                const dateChoosed = dateFormat(new Date(value.trim()), "yyyymmdd");
                setDate(dateChoosed);
                localStorage.setItem("currentDate", dateChoosed);
                fetchDataOrder(`deliveryorder/${dateChoosed}`);
                fetchData(`deliveryoperator/${dateChoosed}`);
                break;

            default:
                break;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        handleFilterData(search, partner, status, vehicle);
    }

    const SampleExpandedComponent = ({ data }) => {
        const values = dataRequest.filter(x => (x.order_id && x.order_id === data.order_id));

        return (
            <DataTable
                data={values}
                columns={columns}
                customStyles={customStyles}
                //keyField='id'
                highlightOnHover={true}
                noHeader={true}
                noTableHead={true}
            />
        );
    }

    const renderFormFilters = () => {
        return (
            <Form body="true" autoComplete="off" onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
                <Row form>
                    <Col sm={12} md={3}>
                        <Input
                            type="date"
                            name="selectDate"
                            defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col sm={12} md={2}>
                        <Input
                            type="select"
                            name="selectPartner"
                            onChange={handleInputChange}
                        >
                            <option value="">-- Partner --</option>
                            <option value='Grab'>Grab</option>
                            <option value='GoGoX'>GoGoX</option>
                            <option value='LaLaMove'>LaLaMove</option>
                            <option value='MilkRun'>MilkRun</option>
                            <option value='Pandago'>PandaGo</option>
                        </Input>
                    </Col>
                    <Col sm={12} md={2}>
                        <Input
                            type="select"
                            name="selectVehicle"
                            onChange={handleInputChange}
                        >
                            <option value="">-- Vehicle --</option>
                            <option value='motorcycle bike instant'>Motorcycle</option>
                            <option value='car sedan bulk'>Car</option>
                            <option value='van minivan'>Van</option>
                            <option value='anyvehicle'>Any Vehicle</option>
                        </Input>
                    </Col>
                    <Col sm={12} md={2}>
                        <Input
                            type="select"
                            name="selectStatus"
                            onChange={handleInputChange}
                        >
                            <option value="">-- Status --</option>
                            <option value='1'>Pending</option>
                            <option value='2'>Assigned</option>
                            <option value='3'>Completed</option>
                            <option value='4'>Cancelled</option>
                            <option value='5'>Rejected</option>
                            <option value='6'>Expired</option>
                            <option value='7'>Picked up</option>
                            <option value='13'>Returned</option>
                            <option value='0'>Fail</option>
                        </Input>
                    </Col>
                    <Col sm={12} md={3}>
                        <InputGroup>
                            <Input
                                name="search"
                                placeholder="Search..."
                                onChange={handleInputChange}
                            />
                            <InputGroupAddon addonType="append">
                                <Button color="success" type="submit">Search</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        )
    }

    const syncRealTime = () => {
        let isRealTime = JSON.parse(localStorage.getItem("isRealTime"));
        localStorage.setItem("isRealTime", JSON.stringify(!isRealTime));

        if (isRealTime) {
            toast.warning('Turn off realtime!', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            toast.success('Turn on realtime!', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <Container className="themed-container delivery-container" fluid={true}>
            <h2 className="h2Class">
                Delivery Tracking
                <SyncSharpIcon fontSize="large" style={{ fill: "red", cursor: "pointer" }} onClick={() => syncRealTime()} />
            </h2>
            <div className="mb-3">
                <DataTable
                    data={dataOrderRequest}
                    columns={columnsOrder}
                    pagination={true}
                    customStyles={customOrderStyles}
                    highlightOnHover={true}
                    noHeader={true}
                    subHeader={true}
                    subHeaderComponent={renderFormFilters()}
                    subHeaderAlign='right'
                    paginationRowsPerPageOptions={[50, 100, 200, 500]}
                    paginationPerPage={50}
                    expandableRows
                    expandableRowsComponent={<SampleExpandedComponent />}
                />
            </div>
            <ToastContainer />
        </Container>
    )
}

export default DeliveryTracking;
