// store.js
import { createStore, combineReducers } from 'redux';
import placeReducer from './reducers/placeReducer';
import dashboardReducer from './reducers/dashboardReducer';
import jobPrintingReducer from './reducers/jobPrintingReducer';
import printingOrderListReducer from './reducers/printingOrderListReducer';
import printingOrderLogDetailReducer from './reducers/printingOrderLogDetailReducer';
import menuAvailabilityReducer from './reducers/menuAvailabilityReducer';
import printerSettingLogsListReducer from './reducers/printerSettingLogsListReducer';
import printoutSettingLogsListReducer from './reducers/printoutSettingLogsListReducer';

const rootReducer = combineReducers({
  places: placeReducer,
  dashboard: dashboardReducer,
  jobPrinting: jobPrintingReducer,
  printingOrderList: printingOrderListReducer,
  printingOrderLogDetailStore: printingOrderLogDetailReducer,
  menuAvailabilityStore: menuAvailabilityReducer,
  printerSettingLogsListReducer: printerSettingLogsListReducer,
  printoutSettingLogsListReducer: printoutSettingLogsListReducer
});

const configureStore = () => {
  return createStore(rootReducer);
}

export default configureStore;
