import React, {Component} from "react";
import {Spinner, Button, Badge} from "reactstrap";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import {database} from "../db/firebase";
import {
    initialDataOrder
} from "../actions/dashboard";
import ListOrder from "../helpers/ListOrder";
import { checkTokenExpire } from '../services/userService';

class RealtimeOrder extends Component {
    constructor() {
        super();
        const currentDate = new Date();
        this.state = {
            tableDataOrder: [],
            ref: "small-orders/" + dateFormat(currentDate, "yyyymmdd"),
            totalOrder: 0,
            totalPickup: 0,
            totalDelivery: 0,
            totalDineinOnline: 0,
            totalDineinPOS: 0,
            isLoading: true,
            isRealtime: false
        };
    }

    async componentDidMount() {
        console.log('componentDidMount');
        checkTokenExpire(() => this.props.history.push('/login'));
        
        let items = await this.fetchDataFromFireBase("value", this.state.ref);
        this.props.initialDataOrder(items);
        this.state.totalOrder = items.length;
        this.state.totalPickup = items.filter(o => o.orderType === 1).length;
        this.state.totalDelivery = items.filter(o => o.orderType === 0).length;
        this.state.totalDineinOnline = items.filter(o => o.orderType === 2 && o.appType != 2).length;
        this.state.totalDineinPOS = items.filter(o => o.orderType === 2 && o.appType === 2).length;
        this.setState({isLoading: !this.state.isLoading});

        let ref = database.ref(this.state.ref);

        if (this.state.isRealtime) {
            database.ref(ref).on("value", snapshot => {
                let data = snapshot.val();
                if (data) {
                    let orders = Object.values(data);
                    console.log("Received a change data: ", orders.length);
                    this.state.totalOrder = orders.length;
                    this.state.totalPickup = orders.filter(o => o.orderType === 1).length;
                    this.state.totalDelivery = orders.filter(o => o.orderType === 0).length;
                    this.state.totalDineinOnline = items.filter(o => o.orderType === 2 && o.appType != 2).length;
                    this.state.totalDineinPOS = items.filter(o => o.orderType === 2 && o.appType === 2).length;
                    this.props.initialDataOrder(orders);
                }

            });
        }

    }

    componentWillUnmount() {
        console.log('componentWillUnmount');
        this.setState({isLoading: true});
        database.ref(this.state.ref).off("value");
    }

    async fetchDataFromFireBase(e, ref) {
        console.log(ref);
        let snapshot = await database.ref(ref).once(e);
        let data = snapshot.val();

        if (data) {
            let items = Object.values(data);
            console.log("items.length:", items.length);
            return items;
        } else {
            return [];
        }
    }

    async updateStatusRealtimeOrder(status) {
        if (status) {
            this.setState({isRealtime: status, isLoading: true});
            await this.componentDidMount();
        } else {
            this.setState({isRealtime: status});
            database.ref(this.state.ref).off("value");
            //await this.componentDidMount();
        }
    }

    render() {
        if (this.state.isLoading) {
            return (<div>
                <Spinner color="primary"/>
            </div>);
        } else {
            return (
                <div>
                    <Button color="primary" size="sm" onClick={() => this.updateStatusRealtimeOrder(true)}>Enable
                        Realtime Status</Button>{' '}
                    <Button color="secondary" size="sm" onClick={() => this.updateStatusRealtimeOrder(false)}>Disable
                        Realtime Status</Button> {' '}
                    <div>{this.state.isRealtime ? <Badge color='primary'>Enabled</Badge> : <Badge color='secondary'>Disabled</Badge>} </div>
                    {ListOrder(this.props.tableDataOrder, this.state.totalOrder, this.state.totalPickup, this.state.totalDelivery, this.state.totalDineinOnline, this.state.totalDineinPOS)}
                </div>
            );
        }

    }
}

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
}

const mapStateToProps = state => {
    return {
        tableDataOrder: state.dashboard.orderData,
        filterData: state.dashboard.filterData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initialDataOrder: orders => {
            dispatch(initialDataOrder(orders));
        },
    };
};

const RealtimeOrderRx = connect(mapStateToProps, mapDispatchToProps)(
    RealtimeOrder
);
export default RealtimeOrderRx;
