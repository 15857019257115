import React from "react";
import dateFormat from "dateformat";
import {Container, Table, Badge } from "reactstrap";

const ICON_PICKUP = require("../assets/icons/icon-pickup.png");
const ICON_DELIVERY = require("../assets/icons/icon-delivery.png");
const ICON_DINEIN = require("../assets/icons/icon-dine-in.png");

const arrayOrderStatus = [
    {orderStatus: 1, status: "New Order"}, //NewOrder
    {orderStatus: 2, status: "Confirmed"}, //ConfirmForDelivery
    {orderStatus: 3, status: "Delivering"}, //Delivering
    {orderStatus: 4, status: "Delivered"}, //Delivered
    {orderStatus: 5, status: "Confirmed"}, //ConfirmForCollection
    {orderStatus: 6, status: ""}, //Received
    {orderStatus: 7, status: "Rejected"}, //Canceled
    {orderStatus: 8, status: "Collected"}, //Collected
    {orderStatus: 9, status: "Refunded"}, //Refunded
    {orderStatus: 10, status: "Amended"}, //Amend
    {orderStatus: 11, status: "New Order"}, //SelfOrderingWaiting
    {orderStatus: 12, status: "Confirmed"}, //SelfOrderingConfirm
    {orderStatus: 17, status: "Sent to Kitchen" }, //ConfirmForDelivery
    {orderStatus: 13, status: "Serving"}, //SelfOrderingServing
    {orderStatus: 14, status: "Completed"}, //SelfOrderingDone
    {orderStatus: 15, status: "Amended"}, //SelfOrderingAmend
    {orderStatus: 20, status: "In Preparation"}, //SendToKitchen
    {orderStatus: 21, status: "Ready For Collection"}, //ReadyForCollection
    {orderStatus: 30, status: "In Preparation"}, //SentToKitchenDelivery
    {orderStatus: 31, status: "Ready For Delivery"}, //ReadyForDelivery
    {orderStatus: 41, status: "Ordering"},
    {orderStatus: 42, status: "Bill Pending"},
    {orderStatus: 43, status: "Bill Now"},
    {orderStatus: 44, status: "Billing"},
    {orderStatus: 45, status: "Paid"},
    {orderStatus: 46, status: "Reserved"}
];

const getOrderStatus = orderStatus => {
    var index = arrayOrderStatus.findIndex(o => o.orderStatus == orderStatus);
    if (index > -1) {
        return arrayOrderStatus[index].status;
    }
    return "N/A";
};

const getBadge = (orderStatus) => {
    var orderStatusStr = getOrderStatus(orderStatus);
    switch (orderStatus) {
        case 1:
        case 11:
        case 41:
            return (
                <Badge color="danger">
                    {orderStatusStr}
                </Badge>
            );
            break;
        case 2:
        case 5:
        case 12:
        case 42:
            return (
                <Badge color="warning">
                    {orderStatusStr}
                </Badge>
            );
            break;
        case 3:
        case 13:
        case 20:
        case 21:
        case 30:
        case 31:
            return (
                <Badge color="success">
                    {orderStatusStr}
                </Badge>
            );
            break;
        case 4:
        case 8:
        case 14:
        case 45:
        case 46:
            return (
                <Badge color="info">
                    {orderStatusStr}
                </Badge>
            );
            break;
        case 7:
        case 9:
            return (
                <Badge color="primary">
                    {orderStatusStr}
                </Badge>
            );
            break;
        default:
            return;
    }
};

const getOrderType = order => {
    if (order.orderType === 0) {
        return 'Delivery';
    } else if (order.orderType === 1) {
        return 'Pickup';
    } else if (order.appType === 2) {
        return 'Dine-in POS';
    } else if (order.orderType === 2) {
        return 'Dine-in Online';
    } else {
        return 'N/A';
    }
};

const renderRows = (orders) => {
    return orders.map(order => {
        let firstPayment = 0;
        if(order.arrayPayment != null && order.arrayPayment.length > 0){
            firstPayment = order.arrayPayment[0].charged;
        }

        return (
            <tr key={order.docId}>
                <td> {getOrderType(order)}</td>
                <td> {order.orderCode} ({dateFormat(new Date(order.modifiedDate), "HH:MM:ss")})</td>
                <td> {order.inventory.businessID} </td>
                <td> {getBadge(order.orderStatus)} </td>
                <td> ${parseFloat(firstPayment).toFixed(2)} </td>
            </tr>
        );
    });
}

const ListOrder = (tableDataOrder, totalOrder, totalPickup, totalDelivery, totalDineinOnline, totalDineinPOS) => {
    return (
        <Container>
            <h2 className="h2Class">Realtime Order status (Total: {totalOrder})</h2>
            <h3 className="h3Class">Pickup: {totalPickup}; Delivery: {totalDelivery}; Dine-in Online: {totalDineinOnline}; Dine-in POS: {totalDineinPOS}</h3>
            <Table striped>
                <thead>
                <tr>
                    <th>Order Type</th>
                    <th>Order Code</th>
                    <th>Store</th>
                    <th>Order Status</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>{renderRows(tableDataOrder)}</tbody>
            </Table>
        </Container>
    );
};

export default ListOrder;
