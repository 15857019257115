import * as firebase from "firebase";
import {apiKey, authDomain, databaseUrl, storeageBucket}  from '../env';

console.log(apiKey);
console.log(authDomain);
console.log(databaseUrl);
console.log(storeageBucket);

// Initialize Firebase
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  databaseURL: databaseUrl,
  storageBucket: storeageBucket
};

firebase.initializeApp(firebaseConfig);

export const database = firebase.database();

export const addDocument = (ref, docId, doc) => {
  firebase
    .database()
    .ref("/" + ref + "/" + docId)
    .set(doc);
};

export const changeDocumentListener = docId => {
  firebase
    .database()
    .ref(docId)
    .on("value", snapshot => {
      const data = snapshot.val();
      console.log("changeDocumentListener:");
      return data;
    });
};

export const signOut = () => {
firebase
  .auth()
  .signOut()
  .then(function() {
    // Sign-out successful.
    console.log('Sign-out successful.');
  })
  .catch(function(error) {
    // An error happened.
  });

};
