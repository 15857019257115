import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Button, Form, InputGroup, Input, InputGroupAddon } from "reactstrap";
import { NavLink } from "react-router-dom";
import * as merchantService from '../../services/merchantService';
import { checkTokenExpire } from '../../services/userService';
import { removeVietnameseTones } from "../../helpers/utils";

const columns = [
    {
        name: 'Business Name',
        selector: 'businessName',
        sortable: true,
        center: true,
        cell: row => <div title={row.businessName}>{row.businessName}
            <input type='hidden' name='merchantID' value={row.merchantID} />
            <input type='hidden' name='bucket' value={row.bucket} />
        </div>,
    },
    {
        name: 'Email',
        sortable: true,
        selector: 'email',
        center: true,
    },
    {
        name: 'Number Outlet',
        sortable: true,
        selector: 'numberOutlet',
        center: true,
    },
    {
        name: 'Action',
        center: true,
        width: '130px',
        cell: row => (
            <NavLink exact to={`/outlet/${row.bucket}`}>
                <Button color='primary' size="sm" >See Outlet</Button>
            </NavLink>
        ),
        button: true
    }
];

const customStyles = {
    rows: {
        style: {
            minHeight: '72px',
            fontSize: '16px',
            backgroundColor: '#f6f6f6',
        }
    },
    headCells: {
        style: {
            fontSize: '16px',
            background: '#6c757d',
            color: '#ffffff',
        },
    },
    cells: {
        style: {
        },
    },
};

const Merchant = ({ ...props }) => {
    const [dataRoot, setDataRoot] = useState([]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState();

    useEffect(() => {
        checkTokenExpire(() => props.history.push('../../login'));
        fetchData();
    }, []);

    const onHistory = () => {
    }

    const fetchData = async () => {
        const value = await merchantService.getListMerchant(onHistory);
        if (value) {
            setDataRoot(value);
            setData(value);
        }
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case 'search':
                setSearch(value.trim());
                break;

            default:
                break;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        if (search && search.length > 0) {
            const textSearch = removeVietnameseTones(search.toString());
            const filterData = dataRoot.filter(x => (x.businessName && x.businessName.match(textSearch))
                || (x.email && removeVietnameseTones(x.email).match(textSearch))
            );

            setData(filterData);
        }
        else {
            setData(dataRoot);
        }
    }

    const renderFormFilters = () => {
        return (
            <Form body="true" autoComplete="off" onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', marginRight: '-15px' }}>
                <InputGroup>
                    <Input
                        name="search"
                        placeholder="Search..."
                        onChange={handleInputChange}
                    />
                    <InputGroupAddon addonType="append">
                        <Button color="success" type="submit">Search</Button>
                    </InputGroupAddon>
                </InputGroup>
            </Form>
        )
    }

    return (
        <Container className="themed-container">
            <h2 className="h2Class">Merchants</h2>
            <div className="mb-3">
                <DataTable
                    data={data}
                    columns={columns}
                    pagination={true}
                    customStyles={customStyles}
                    keyField='id'
                    highlightOnHover={true}
                    noHeader={true}
                    subHeader={true}
                    subHeaderComponent={renderFormFilters()}
                    subHeaderAlign='right'
                    paginationRowsPerPageOptions={[50, 100, 200, 500]}
                    paginationPerPage={50}
                />
            </div>
        </Container>
    )
}

export default Merchant;
