
import {
    FILTER_DATA,
    INITIAL_PRINTING_ORDER_LOG_DETAIL
} from "../actions/types";

const initialState = {
    logDetail: [],
};

const filterAllData = logDetail => {
    return {
        logDetail: logDetail
            .sort(function (a, b) {
                const nameA = a.orderCode.toUpperCase(); // ignore upper and lowercase
                const nameB = b.orderCode.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            .slice(0, 100),
    };
};

const printingOrderLogDetailReducer = (state = initialState, action) => {
    //console.log(action.type);
    switch (action.type) {
        case FILTER_DATA:
            return {
                ...state,
                logDetail: action.payload.logDetail
            };
        case INITIAL_PRINTING_ORDER_LOG_DETAIL:
            const {logDetail} = filterAllData( 
                action.payload.logDetail
            );
            return {
                ...state,
                logDetail: logDetail,
            };

        default:
            return state;
    }
};

export default printingOrderLogDetailReducer;
