import React, {Component} from "react";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import {database} from "../db/firebase";
import {initialJobOrderPrintingLog} from "../actions/jobPrinting";
import ListLogs from "../helpers/ListLogs";
import { checkTokenExpire } from '../services/userService';

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

class JobOrderPrintingLog extends Component {
    constructor(props) {
        super(props);

        let currentDate = new Date();
        const currentDateString = JSON.parse(localStorage.getItem('currentDate'));
        if(currentDateString) {
            currentDate = new Date(currentDateString);
        }
        
        let orderID = '';
        if(this.props.location.state && this.props.location.state.orderID ) {
            orderID = this.props.location.state.orderID;
        }
        else if(localStorage.getItem('orderID')){
            orderID = JSON.parse(localStorage.getItem('orderID'));
        }
        
        this.state = {
            logs: [],
            ref: "order-print-logs-detail/" + dateFormat(currentDate, "yyyymmdd") + "/" + orderID,//this.props.location.state.orderID,
        };
    }

    async componentDidMount() {
        console.log('componentDidMount');
        checkTokenExpire(() => this.props.history.push('/login'));

        if((this.props.location.state === undefined || this.props.location.state.orderID === undefined) && localStorage.getItem('orderID') === undefined) {
            this.props.history.push('/printingOrders')
        }

        const state_current = this;

        let items = await this.fetchDataFromFireBase("value", this.state.ref);
        console.log(items)
        this.props.initialJobOrderPrintingLog(items);

        let ref = database.ref(this.state.ref);
        database.ref(ref).on("value", snapshot => {
            if(snapshot.val()){
                let data = snapshot.val();
                let logs = Object.values(data);
                this.props.initialJobOrderPrintingLog(logs);
            }
        });
    }

    componentWillUnmount() {
        database.ref(this.state.ref).off("value");
    }


    async fetchDataFromFireBase(e, ref) {
        let snapshot = await database.ref(ref).once(e);
        let data = snapshot.val();
        if (data) {
            let items = Object.values(data);
            console.log("items.length:", items.length);
            return items;
        } else {
            return [];
        }
    };

    onLoadLogDetail = (orderID, requestID) => {
        localStorage.setItem('orderID', JSON.stringify(orderID));
        localStorage.setItem('requestID', JSON.stringify(requestID));
        this.props.history.push({
             pathname: `/printingOrderLogDetail/${requestID}`,
            state: {
                requestID: requestID,
                orderID: orderID
            },
        });
    }

    onBackPage = () => {
        this.props.history.push('/printingOrders');
    }

    render() {
        return (
            ListLogs(this.props.logs, this.onLoadLogDetail, this.onBackPage)
        );
    }
}


const mapStateToProps = state => {
    return {
        logs: state.jobPrinting.logs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initialJobOrderPrintingLog: logs => {
            dispatch(initialJobOrderPrintingLog(logs));
        },
    };
};

const JobOrderPrintingLogRx = connect(mapStateToProps, mapDispatchToProps)(
    JobOrderPrintingLog
);
export default JobOrderPrintingLogRx;
