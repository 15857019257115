import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Button, Badge, Row, Col } from "reactstrap";
import { useParams, NavLink } from "react-router-dom";
import * as merchantService from '../../services/merchantService';
import { checkTokenExpire } from '../../services/userService';

const columns = [
    {
        name: 'BusinessID',
        selector: 'businessID',
        sortable: true,
        center: true,
        cell: row => <div title={row.businessID}>{row.businessID}
            {/* <NavLink exact to={`/outlet/${row.bucket}`}> {row.businessName} </NavLink> */}
            <input type='hidden' name='docId' value={row.docId} />
        </div>,
    },
    {
        name: 'OS Display Name',
        sortable: true,
        selector: 'onlineShopDisplayName',
        center: true,
        cell: row => <div title={row.onlineShopDisplayName}>{row.onlineShopDisplayName}</div>,
    },
    {
        name: 'Status',
        sortable: true,
        selector: 'status',
        center: true,
        cell: row => <Badge color={row.status ? 'success' : 'danger'}>{row.status ? 'Active' : 'Inactive'}</Badge>
    },
    {
        name: 'Action',
        center: true,
        width: '130px',
        cell: row => (
            <NavLink exact to={`/station/${row.bucket}/${row.index}`}>
                <Button color='primary' size="sm" >See Station</Button>
            </NavLink>
        ),
        button: true
    }
];

const customStyles = {
    rows: {
        style: {
            minHeight: '72px',
            fontSize: '16px',
            backgroundColor: '#f6f6f6',
        }
    },
    headCells: {
        style: {
            fontSize: '16px',
            background: '#6c757d',
            color: '#ffffff',
        },
    },
    cells: {
        style: {
        },
    },
};

const Outlet = ({ ...props }) => {
    const { bucket } = useParams();

    const [data, setData] = useState([]);

    useEffect(() => {
        checkTokenExpire(() => props.history.push('../../login'));

        const onHistory = () => {
            console.log('error');
        }

        const fetchData = async () => {
            const value = await merchantService.getListOutlet(onHistory, bucket);
            if (value) {
                setData(value.filter(x => x.index > 1));
            }
        };

        fetchData();
    }, []);

    return (
        <Container className="themed-container">
            <Row>
                <Col sm='1'>
                    <NavLink exact to='/merchant'>
                        <Button>Back</Button>
                    </NavLink>
                </Col>
                <Col sm='10'>
                    <h2 className="h2Class">Outlet</h2>
                </Col>
                <Col sm='1'></Col>
            </Row>
            <div className="mb-3">
                <DataTable
                    data={data}
                    columns={columns}
                    pagination={true}
                    customStyles={customStyles}
                    keyField='id'
                    highlightOnHover={true}
                    noHeader={true}
                    paginationRowsPerPageOptions={[15, 30, 50, 100]}
                    paginationPerPage={15}
                />
            </div>
        </Container>
    )
}

export default Outlet;
