import React from "react";
import { Button, Form, Input, InputGroup, InputGroupAddon, Badge, Row, Col } from "reactstrap";
import DataTable from 'react-data-table-component';
import { database } from "../db/firebase";
import { removeVietnameseTones, formatDateTime } from "../helpers/utils";
import ReactHtmlParser from 'react-html-parser';

const customStyles = {
    rows: {
        style: {
            minHeight: '72px',
            fontSize: '16px',
            backgroundColor: '#f6f6f6',
        }
    },
    headCells: {
        style: {
            fontSize: '16px',
            background: '#6c757d',
            color: '#ffffff',
        },
    },
    cells: {
        style: {
        },
    },
};

const conditionalRowStyles = [
    {
        when: row => row.display === "Available",
        style: {
            backgroundColor: 'darkgray',
            color: 'white',
            '&:hover': {
                backgroundColor: '#6C757D',
                color: 'white'
            },
        },
    }
];

const column = [
    {
        name: 'Title',
        selector: 'title',
        sortable: true,
        center: true,
        cell: row => <div title={row.title}>{row.title}</div>,
    },
    {
        name: 'Outlet Name',
        selector: 'outletName',
        sortable: true,
        center: true,
        cell: row => <div title={row.outletName}>{row.outletName}</div>,
    },
    {
        name: 'Business Name',
        selector: 'businessName',
        sortable: true,
        center: true,
        cell: row => <div title={row.businessName}>{row.businessName} <input type='hidden' value={row.id} /></div>,
    },
    {
        name: 'Item Type',
        sortable: true,
        selector: 'isModifierItem',
        center: true,
        width: '130px',
        cell: row => {
            if (row.isModifierItem === true) {
                return 'Modifier';
            }
            else if (row.isModifierItem === false) {
                return 'Product';
            }
        },
    },
    {
        name: 'Group Name',
        selector: 'groupName',
        sortable: true,
        center: true,
        cell: row => <div>{row.groupName}</div>,
    },
    {
        name: 'Create Date',
        selector: 'createdDate',
        sortable: true,
        center: true,
        cell: row => <div title={row.createdDate}>{row.createdDate}</div>,
    },
    {
        name: 'Status',
        selector: 'status',
        width: '100px',
        sortable: true,
        center: true,
        cell: row => {
            let statusMessage = 'Waiting';
            let statusColor = 'warning';
            if (row.status === 2) {
                statusMessage = 'Fail (' + row.totalrequest + ')';
                statusColor = 'danger';
            }
            else if (row.status === 1) {
                statusMessage = 'Success';
                statusColor = 'success';
            }
            return (
                <Badge color={statusColor}>{statusMessage}</Badge>
            )
        },
    },
    {
        name: 'From',
        selector: 'from',
        sortable: true,
        width: '100px',
        center: true,
        cell: row => <div title={row.from}>{row.from}</div>,
    },
    {
        name: 'MA Type',
        selector: 'menuTypeDashboard',
        sortable: true,
        width: '120px',
        center: true,
        cell: row => <div title={row.menuTypeDashboard} style={row.display === "Available" && row.menuTypeDashboard !== "Available" ? { textDecoration: 'line-through'} : {}} >{row.menuTypeDashboard}</div>,
    },
];


const renderSelectTime = (handleInputChange, currentDate) => {
    return (
        <Input
            type="date"
            name="selectDate"
            defaultValue={currentDate}
            onChange={handleInputChange}
        />
    )
}

const renderSelectStatus = (handleInputChange) => {
    return (
        <Input
            type="select"
            name="selectStatus"
            onChange={handleInputChange}
        >
            <option value="">Status</option>
            <option value='0'>Waiting</option>
            <option value='1'>Success</option>
            <option value='2'>Fail</option>
        </Input>
    )
}

const renderOptions = data => {
    if (data && data.length > 0) {
        const outlets = Array.from(new Set(data))
            .map(d => {
                return d['outletName'];
            }).filter((v, i, a) => a.indexOf(v) === i && v);

        //let unique = [...new Set(outlets)]; 
        return outlets.map(outlet => {
            return (
                <option key={outlet.replaceAll(" ", "-")} value={outlet}>{outlet}</option>
            );
        });
    }
}

const renderSelectOutlet = (handleInputChange, data) => {
    return (
        <Input
            type="select"
            name="selectOutlet"
            onChange={handleInputChange}
        >
            <option value="">Outlet</option>
            {renderOptions(data)}
        </Input>
    )
}

const renderFormFilters = (handleSubmit, handleInputChange, currentDate, data) => {
    return (
        <Form body="true" autoComplete="off" onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', marginRight: '-10px' }}>
            <Row form>
                <Col sm={12} md={3}>
                    {renderSelectTime(handleInputChange, currentDate)}
                </Col>
                <Col sm={12} md={2}>
                    {renderSelectStatus(handleInputChange)}
                </Col>
                <Col sm={12} md={2}>
                    {renderSelectOutlet(handleInputChange, data)}
                </Col>
                <Col sm={12} md={5}>
                    <InputGroup>
                        <Input
                            name="searchMA"
                            placeholder="Search..."
                            onChange={handleInputChange}
                        />
                        <InputGroupAddon addonType="append">
                            <Button color="success" type="submit">Search</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
        </Form>
    )
}

export const renderTablePage = (dataFilter, handleSubmit, handleClick, handleInputChange, handleDetailClick, currentDate, fullData) => {
    const data = Array.from(new Set(dataFilter))
        .map(d => {
            return {
                id: d.id,
                subId: d.id.split(/[\s-]+/).pop(),
                title: d.title,
                createdDate: d.createdDate ? formatDateTime(new Date(d.createdDate + 'Z')) : formatDateTime(new Date(null)),
                status: d.status,
                employeeID: d.employeeID,
                menuAvailabilityId: d.menuAvailabilityId,
                message: d.message,
                bucket: d.bucket,
                isModifierItem: d.isModifierItem,
                groupName: ReactHtmlParser(d.groupName),
                from: d.from,
                display: d.display ? "Unavailable" : "Available",
                validityPeriodType: d.validityPeriodType,
                modifiedDate: d.modifiedDate ? formatDateTime(new Date(d.modifiedDate)) : '',
                businessName: d.businessName,
                outletName: d.outletName,
                totalrequest: d.totalrequest,
                menuTypeDashboard: d.menuTypeDashboard,
            };
        }).filter(d => d.id !== undefined);

    data.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : ((b.createdDate < a.createdDate) ? -1 : 0));

    const columns = [...column,
    {
        name: 'Action',
        center: true,
        width: '130px',
        cell: row => {
            if (row.status === 2 || row.status === 0) {
                return (
                    <div style={{ display: "inline-flex" }}>
                        <Button className="mr-1" color='info' size="sm" onClick={() => handleClick(row.id)}>Reflect</Button>
                        <Button color='primary' size="sm" onClick={() => handleDetailClick(row.id)}>Detail</Button>
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <Button color='primary' size="sm" onClick={() => handleDetailClick(row.id)}>Detail</Button>
                    </div>
                )
            }
        },
        button: true
    }]

    return (
        <DataTable
            data={data}
            columns={columns}
            pagination={true}
            customStyles={customStyles}
            keyField='id'
            highlightOnHover={true}
            noHeader={true}
            subHeader={true}
            subHeaderComponent={renderFormFilters(handleSubmit, handleInputChange, currentDate, fullData)}
            subHeaderAlign='right'
            conditionalRowStyles={conditionalRowStyles}
            paginationRowsPerPageOptions={[100, 250, 500]}
            paginationPerPage={100}
        />
    )
}

export const onDataFromFireBase = (e, ref, initMenuAvailability) => {
    database.ref(ref).on(e, snapshot => {
        if (snapshot.val()) {
            const data = Object.values(snapshot.val());
            initMenuAvailability(data, data);
        }
    });
};

export const offDataFromFireBase = async (e, ref) => {
    database.ref(ref).off(e);
};

export const updateDataFromFireBase = async (data, ref) => {
    database
        .ref(ref)
        .child(data.id)
        .update(data)
        .then(() => console.log('update success'))
        .catch(error => console.log(error));

    // let ref = firebase
    //   .database()
    //   .ref(docId);

    // return ref.child(data.id)
    //   .update(data)
    //   .then(() => ref.once('value'))
    //   .then(snapshot => snapshot.val())
    //   .catch(error => ({
    //     errorCode: error.code,
    //     errorMessage: error.message
    //   }));
};

export const fetchDataFromFireBase = async (e, ref) => {
    let snapshot = await database.ref(ref).once(e);
    let data = snapshot.val();
    if (data) {
        let items = Object.values(data);
        return items;
    } else {
        return [];
    }
};

export const finddata = (data, searchMA, selectStatus, selectOutlet) => {
    let dataFound = data.filter(o => o.id !== undefined);

    if (searchMA && searchMA.length > 0) {
        let textSerchOrder = removeVietnameseTones(searchMA);
        dataFound = dataFound.filter(x => (x.businessName && x.businessName.match(textSerchOrder))
            || (x.title && removeVietnameseTones(x.title).match(textSerchOrder))
            || (x.outletName && removeVietnameseTones(x.outletName).match(textSerchOrder))
        );
    }

    if (selectStatus && selectStatus.length > 0) {
        const status = parseInt(selectStatus);
        dataFound = dataFound.filter(x => x.status === status);
    }

    if (selectOutlet && selectOutlet.length > 0) {
        console.log(dataFound.filter(x => x.outletName === selectOutlet));
        dataFound = dataFound.filter(x => x.outletName === selectOutlet);
    }

    return dataFound;
}