export const ADD_PLACE = 'ADD_PLACE';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PROMOTION = 'ADD_PROMOTION';
export const INITIAL_ORDER_DATA = 'INITIAL_ORDER_DATA';
export const FILTER_DATA = 'FILTER_DATA';
export const INITIAL_JOB_ORDER_PRINTING_LOG = 'INITIAL_JOB_ORDER_PRINTING_LOG';
export const INITIAL_PRINTING_ORDER_LIST = 'INITIAL_PRINTING_ORDER_LIST';
export const INITIAL_PRINTING_ORDER_LOG_DETAIL = 'INITIAL_PRINTING_ORDER_LOG_DETAIL';
export const INITIAL_MENU_AVAILABILITY = 'INITIAL_MENU_AVAILABILITY';
export const INITIAL_PRINTER_SETTING_LOG_LIST = 'INITIAL_PRINTER_SETTING_LOG_LIST';
export const INITIAL_PRINTOUT_SETTING_LOG_LIST = 'INITIAL_PRINTOUT_SETTING_LOG_LIST';