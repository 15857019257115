import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Container, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { checkTokenExpire, logout } from '../services/userService';
//import socketIOClient from "socket.io-client";
import "./header.css";

// The Header creates links that can be used to navigate
// between routes.
var socket;
class Header extends Component {
	constructor() {
		super();

		const href = window.location.href;
		let isMA = '';
		if (href.includes('maToday')
			|| href.includes('maUntilNotice')
			|| href.includes('maCustom')
		) {
			isMA = 'active';
		}

		this.state = {
			isLogin: checkTokenExpire(() => { }),
			dropdownOpen: false,
			isActive: isMA,
			endpoint: "http://localhost:3001/" // Update 3001 with port on which backend-my-app/server.js is running.
		};
		//socket = socketIOClient(this.state.endpoint);
	}

	toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });;

	handleSignoutClick = () => logout(() => window.location.reload(false));

	handleNavLinkClick = (e) => {
		const { className } = e.target;

		if (className.includes('link-dropdown')) {
			this.setState({ isActive: "active" });
		}
		else {
			this.setState({ isActive: "" });
		}
	}

	render() {
		if (this.state.isLogin) {
			return (
				<header id="header">
					<Container className="themed-container">
						<nav>
							<ul className="NavClass">
								<li>
									<NavLink onClick={this.handleNavLinkClick} to="/home">
										<img src={process.env.PUBLIC_URL + '/getz_logo.svg'} alt="logo" className="logo-getz" />
									</NavLink>
								</li>
								<li>
									<NavLink onClick={this.handleNavLinkClick} to="/merchant" className="link-service">Stations Panel</NavLink>
								</li>
								<Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
									<DropdownToggle nav caret id="menu-availability" className={`link-service ${this.state.isActive}`}>
										Menu Availability
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem header>
											<NavLink onClick={this.handleNavLinkClick} to="/maToday" className="link-dropdown">MA (Today)</NavLink>
										</DropdownItem>
										<DropdownItem divider />
										<DropdownItem header>
											<NavLink onClick={this.handleNavLinkClick} to="/maUntilNotice" className="link-dropdown">MA (Until Further Notice)</NavLink>
										</DropdownItem>
										<DropdownItem divider />
										<DropdownItem header>
											<NavLink onClick={this.handleNavLinkClick} to="/maCustom" className="link-dropdown">MA (Custom)</NavLink>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<li>
									<NavLink onClick={this.handleNavLinkClick} to="/merchantDomains" className="link-service">Merchant Domains</NavLink>
								</li>
								<li>
									<NavLink onClick={this.handleNavLinkClick} to="/printingOrders" className="link-service">PASS Print Orders</NavLink>
								</li>
								<li>
									<NavLink onClick={this.handleNavLinkClick} to="/printingPOSOrders" className="link-service">POS Print Orders</NavLink>
								</li>
								<li>
									<NavLink onClick={this.handleNavLinkClick} to="/deliveryTracking" className="link-service">Delivery</NavLink>
								</li>
								<li>
									<NavLink onClick={this.handleNavLinkClick} to="/LogMerchant" className="link-service">Device Logs</NavLink>
								</li>
								<li>
									<NavLink onClick={() => this.handleSignoutClick()} to="" className="signout">Signout</NavLink>
								</li>
							</ul>
						</nav>
					</Container>
				</header>
			);
		}
		else {
			return (
				<h1> </h1>
			)
		}
	}
}

export { Header, socket };
