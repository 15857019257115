import {
    FILTER_DATA,
    INITIAL_MENU_AVAILABILITY
} from "../actions/types";

const initialState = {
    data: [],
    dataFilter: [],
};

const filterAllData = data => {
    return {
        data: data
            .sort(function (a, b) {
                const nameA = a.id.toUpperCase(); // ignore upper and lowercase
                const nameB = b.id.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            //.slice(0, 100),
    };
};

const todayMAReducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER_DATA:
            return {
                ...state,
                data: action.payload.data
            };
        case INITIAL_MENU_AVAILABILITY:
            const {data} = filterAllData(
                action.payload.data
            );
            
            return {
                ...state,
                data: data,
                dataFilter: action.payload.dataFilter
            };

        default:
            return state;
    }
};

export default todayMAReducer;
