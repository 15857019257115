import React, { Component, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { database } from "../../db/firebase";
import { initialPrinterSettingLogsList } from "../../actions/printerSettingLogsList";
import { removeVietnameseTones, showDateTimeWithTimezone } from "../../helpers/utils";
import { checkTokenExpire } from '../../services/userService';
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom";

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
}

class PrinterSettingLogs extends Component {
    constructor(props) {
        super(props);

        let currentDate = new Date();
        const currentDateString = JSON.parse(localStorage.getItem('currentDate'));
        if(currentDateString) {
            currentDate = new Date(currentDateString);
        }

        var merchantID = this.props.match.params.bucket;
        const searchOrder = localStorage.getItem('searchOrder') === undefined ? '' : JSON.parse(localStorage.getItem('searchOrder'));

        this.state = {
            logs: [],
            ref: "printer-setting-log/" + merchantID + "/" + dateFormat(currentDate, "yyyymmdd"),
            logsFilters: [],
            orderDate: dateFormat(currentDate, "yyyy-mm-dd"),
            searchOrder: searchOrder,
            merchantID: merchantID
            // this is where we are connecting to with sockets,
        };
    }

    async componentDidMount() {
        console.log('componentDidMount');
        checkTokenExpire(() => this.props.history.push('/login'));
        const state_current = this;
        let logs = await this.fetchDataFromFireBase("value", this.state.ref);
        this.initPrintingOrder(logs);
        let ref = database.ref(this.state.ref);
        database.ref(ref).on("value", snapshot => {
            if (snapshot.val()) {
                let data = snapshot.val();
                let logs = Object.values(data);
                this.initPrintingOrder(logs);
            }
        });
    }

    componentWillUnmount() {
        database.ref(this.state.ref).off("value");
    }

    async fetchDataFromFireBase(e, ref) {
        let snapshot = await database.ref(ref).once(e);
        let data = snapshot.val();
        if (data) {
            let items = Object.values(data);
            return items;
        } else {
            return [];
        }
    };

    initPrintingOrder = logs => {
        this.props.initialPrinterSettingLogsList(logs, this.findOrders(logs));
    }

    findOrders = (orders) => {
        let orderFound = orders.filter(o => o.merchantID !== undefined);

        if (this.state.searchOrder && this.state.searchOrder.length > 0) {
            let textSerchOrder = removeVietnameseTones(this.state.searchOrder);
            orderFound = orderFound.filter(x => removeVietnameseTones(x.printerID).match(textSerchOrder) 
                || removeVietnameseTones(x.printerName).match(textSerchOrder)
                || removeVietnameseTones(x.email).match(textSerchOrder)
                || x.employeeID.match(textSerchOrder)
            );
        }

         return orderFound;
    }

    handleInputChange = async e => {
        const { name, value } = e.target;

        switch (name) {
            case 'searchOrder':
                this.setState({ searchOrder: value });
                localStorage.setItem('searchOrder', JSON.stringify(value));
                break;

            case 'orderDate':
                var dateSelected = new Date(value);
                var ref = "printer-setting-log/" + this.state.merchantID + "/" + dateFormat(dateSelected, "yyyymmdd");
                this.setState({
                    ref: ref,
                    orderDate: value
                });

                let logs = await this.fetchDataFromFireBase("value", ref);
                const logsFound = await this.findOrders(logs);

                this.props.initialPrinterSettingLogsList(logs, logsFound);
                localStorage.setItem('currentDate', JSON.stringify(value));
                break;

            default:
                break;
        }
    }

    handleSubmit = async e => {
        e.preventDefault();

        const logsFound = await this.findOrders(this.props.logs);
        this.props.initialPrinterSettingLogsList(this.props.logs, logsFound);
    }

    handleClickReset = () => {
        localStorage.removeItem("currentDate");
        localStorage.removeItem("searchOrder");
        localStorage.removeItem("orderID");
        localStorage.removeItem("requestID");

        window.location.reload(false);
    }

    render() {
        return (
            <Container fluid>
                <h2 className="h2Class">Merchant Printer Setting Logs</h2>
                <div className="mb-3">
                    <Form body="true" autoComplete="off" onSubmit={this.handleSubmit}>
                        <Row form>
                            <Col sm="12" md={{ size: 2 }}>
                                <FormGroup>
                                    <Label for="orderDate" hidden>Date</Label>
                                    <Input
                                        type="date"
                                        name="orderDate"
                                        id="orderDate"
                                        defaultValue={this.state.orderDate}
                                        placeholder="choose date"
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            
                            <Col sm="3" md="3">
                                <InputGroup>
                                    <Input
                                        name="searchOrder"
                                        placeholder="Search..."
                                        onChange={this.handleInputChange}
                                        defaultValue={this.state.searchOrder}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button color="success" type="submit">Search</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col sm="6" md="1">
                                <InputGroup>
                                    <Button color="danger" type="button" onClick={this.handleClickReset}>Reset</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div>
                    {this.renderTablePage(this.props.logsFilters)}
                </div>
            </Container>
        );
    }

    handleClick(orderID) {
        localStorage.setItem('orderID', JSON.stringify(orderID));
        this.props.history.push({
            //pathname: "/jobOrderPrintingLogs",
            pathname: `/jobOrderPrintingLogs/${orderID}`,
            state: { orderID: orderID },
        });
    }

    renderOptions(orders) {
        if (orders && orders.length > 0) {
            const outlets = Array.from(new Set(orders.map(x => x.storeID)))
                .map(storeID => {
                    return {
                        storeID: storeID,
                        storeName: orders.find(s => s.storeID === storeID).storeName
                    };
                })
                .filter(o => o.storeID !== undefined);

            return outlets.map(outlet => {
                if (outlet.storeID) {
                    return (
                        <option key={outlet.storeID} value={outlet.storeID}>{outlet.storeName}</option>
                    );
                }
                // else {
                //     return '';
                // }
            });
        }
    }

    renderTablePage(logs) {
        const data = Array.from(new Set(logs))
            .map(log => {
                return {
                    printerName: log.printerName,
                    printerID: log.printerID,
                    printerIpAddress: log.printerIpAddress,
                    printerPort: log.printerPort,
                    requestID: log.requestID,
                    email: log.email,
                    printerUpdateType: log.printerUpdateType == 0 ? "Add" : log.printerUpdateType == 1 ? "Update" : log.printerUpdateType == 2 ? "Delete" : "N/A",
                    modifiedDate: dateFormat(new Date(log.requestDate), "yyyy-mm-dd'T'HH:MM:ss")
                };
            });

        data.sort((a, b) => (a.modifiedDate < b.modifiedDate) ? 1 : ((b.modifiedDate < a.modifiedDate) ? -1 : 0));

        const columns = [
            {
                name: 'Printer Name',
                selector: 'printerName',
                sortable: true,
                center: true,
            },
            {
                name: 'Printer ID',
                selector: 'printerID',
                sortable: true,
                center: true,

            },
            {
                name: 'Printer Ip Address',
                selector: 'printerIpAddress',
                sortable: true,
                center: true,

            },
            {
                name: 'Printer Port',
                selector: 'printerPort',
                center: true,
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
                center: true,

            },
            {
                name: 'Printer Update Type',
                selector: 'printerUpdateType',
                center: true,
            },
            {
                name: 'Modified Date',
                selector: 'modifiedDate',
                sortable: true,
                center: true,
            },
            {
                name: 'Actions',
                center: true,
                cell: row => {
                    return (
                        <NavLink exact to={`/printerSettingLogDetails/${this.state.merchantID}/${dateFormat(this.state.orderDate, "yyyymmdd")}/${row.requestID}`}>
                            <Button color='primary' size="sm">Detail</Button>
                        </NavLink>
                    )
                },
                button: true
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '72px',
                    fontSize: '16px',
                    backgroundColor: '#f6f6f6',
                }
            },
            headCells: {
                style: {
                    fontSize: '16px',
                    background: '#6c757d',
                    color: '#ffffff',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                },
            },
        };

        return (
            <DataTable
                data={data}
                columns={columns}
                pagination={true}
                customStyles={customStyles}
                keyField='orderID'
                highlightOnHover={true}
                noHeader={true}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        logs: state.printerSettingLogsListReducer.logs,
        logsFilters: state.printerSettingLogsListReducer.logsFilters,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initialPrinterSettingLogsList: (logs, logsFilters) => {
            dispatch(initialPrinterSettingLogsList(logs, logsFilters));
        },
    };
};

const PrinterSettingLogsRx = connect(mapStateToProps, mapDispatchToProps)(
    PrinterSettingLogs
);
export default PrinterSettingLogsRx;
