import React, { useState, useEffect } from "react";
import { database } from "../../db/firebase";
import { checkTokenExpire } from '../../services/userService';
import { Container, Table, Button, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";;

const PrinterSettingLogDetails = ({ ...props }) => {
    const { bucket, date, request } = useParams();
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            checkTokenExpire(() => props.history.push('../../../login'));
            setData(await fetchDataFromFireBase("value", `printer-setting-log/${bucket}/${date}/${request}`));
        };

        fetchData();
    }, []);

    const fetchDataFromFireBase = async (e, ref) => {
        let snapshot = await database.ref(ref).once(e);
        let data = snapshot.val();
        if (data) {
            return data;
        } else {
            return {};
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    <NavLink exact to={`/printerSettingLogs/${bucket}`}>
                        <Button className="float-left" color="info">Back</Button>
                    </NavLink>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <h2 className="h2Class">Printer Setting Log Details</h2>
                    <Table striped>
                        <tbody>
                            {Object.entries(data).map(([key, value]) =>
                                <tr key={key}>
                                    <td className="h3Class" style={{ fontWeight: "bold" }}>{key} </td>
                                    <td>{value} </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

export default PrinterSettingLogDetails;
