import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
//import { BrowserRouter as Router, Route, HashRouter } from 'react-router-dom'
import {Route, HashRouter} from 'react-router-dom'
import App from '../App'

const Root = ({store}) => (
    <Provider store={store}>
        <HashRouter>
            <Route path="/:filter?" component={App}/>
        </HashRouter>
    </Provider>
)

Root.propTypes = {
    store: PropTypes.object.isRequired
}

export default Root