import React from "react";
import { Container, Table, Badge } from "reactstrap";
import dateFormat from "dateformat";

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
}

export const showDateTimeWithTimezone = date => {
    date = new Date(date.slice(0, -1));//remove 'Z' at last string

    date.setHours(date.getHours() + 8);
    //return dateFormat(date, "yyyy-mm-dd'T'HH:MM:ss")
    return dateFormat(date, "yyyy-mm-dd HH:MM:ss")
};

export const showPrintingType = types => {
    var typesStr = [];
    types.forEach(function (type) {
        const index = arrayTypeReceipt.findIndex(o => o.status === type);
        if (index > -1) {
            typesStr.push(arrayTypeReceipt[index].name);

        }
    })
    return typesStr.join(", ");
};


const arrayTypeReceipt = [
    { status: -1, name: "N/A" },
    { status: 0, name: "Receipt" },
    { status: 1, name: "Receipt Copy" },
    { status: 2, name: "Single Order Chit" },
    { status: 3, name: "Total Order Chit" },
    { status: 4, name: "Rider Print Out" },
    { status: 5, name: "Report" },
    { status: 6, name: "Pre-order Receipt" },
    { status: 7, name: "Marketing Receipt" },
];

export const removeVietnameseTones = str => {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    str = str.replace(/đ/g, "d");
    return str;
}

export const formatDateTime = (d) => {
    var mm = '' + (d.getMonth() + 1),
        dd = '' + d.getDate(),
        yyyy = d.getFullYear(),
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes(),
        seconds = '' + d.getSeconds();

    if (mm.length < 2) mm = '0' + mm;
    if (dd.length < 2) dd = '0' + dd;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;

    return yyyy + '-' + mm + '-' + dd + ' ' + hours + ':' + minutes + ':' + seconds;
}
