import React from "react";
import { Container, Table, Badge, Button, Row, Col } from "reactstrap";
import DataTable from 'react-data-table-component';
import { showPrintingType } from "./utils";
import { showDateTimeWithTimezone } from "./utils";

const arrayOrderStatus = [
    { orderStatus: 1, status: "New Order" }, //NewOrder
    { orderStatus: 2, status: "Confirmed" }, //ConfirmForDelivery
    { orderStatus: 3, status: "Delivering" }, //Delivering
    { orderStatus: 4, status: "Delivered" }, //Delivered
    { orderStatus: 5, status: "Confirmed" }, //ConfirmForCollection
    { orderStatus: 6, status: "" }, //Received
    { orderStatus: 7, status: "Rejected" }, //Canceled
    { orderStatus: 8, status: "Collected" }, //Collected
    { orderStatus: 9, status: "Refunded" }, //Refunded
    { orderStatus: 10, status: "Amended" }, //Amend
    { orderStatus: 11, status: "New Order" }, //SelfOrderingWaiting
    { orderStatus: 12, status: "Confirmed" }, //SelfOrderingConfirm
    { orderStatus: 13, status: "Serving" }, //SelfOrderingServing
    { orderStatus: 14, status: "Completed" }, //SelfOrderingDone
    { orderStatus: 17, status: "Sent to Kitchen" }, //SelfOrderingDone
    { orderStatus: 15, status: "Amended" }, //SelfOrderingAmend
    { orderStatus: 20, status: "In Preparation" }, //SendToKitchen
    { orderStatus: 21, status: "Ready For Collection" }, //ReadyForCollection
    { orderStatus: 30, status: "In Preparation" }, //SentToKitchenDelivery
    { orderStatus: 31, status: "Ready For Delivery" }, //ReadyForDelivery
    { orderStatus: 41, status: "Ordering" },
    { orderStatus: 42, status: "Bill Pending" },
    { orderStatus: 43, status: "Bill Now" },
    { orderStatus: 44, status: "Billing" },
    { orderStatus: 45, status: "Paid" },
    { orderStatus: 46, status: "Reserved" }
];

export const getOrderStatus = orderStatus => {
    var index = arrayOrderStatus.findIndex(o => o.orderStatus == orderStatus);
    if (index > -1) {
        return arrayOrderStatus[index].status;
    }
    return "N/A";
};

export const getPrintingStatus = (printingStatus) => {
    var printingStatusStr = printingStatus === 1 ? "Success" : printingStatus === 0 ? "Fail" : "N/A";
    switch (printingStatus) {
        case 0:
            return (
                <Badge color="danger">
                    {printingStatusStr}
                </Badge>
            );
        case 1:
            return (
                <Badge color="success">
                    {printingStatusStr}
                </Badge>
            );
        case 2:
            return (
                <Badge color="light">
                    {printingStatusStr}
                </Badge>
            );
        default:
            return;
    }
};

export const getBadge = (orderStatus) => {
    var orderStatusStr = getOrderStatus(orderStatus);
    switch (orderStatus) {
        case 1:
        case 11:
        case 41:
        case 17:
            return (
                <Badge color="danger">
                    {orderStatusStr}
                </Badge>
            );
        case 2:
        case 5:
        case 12:
        case 42:
            return (
                <Badge color="warning">
                    {orderStatusStr}
                </Badge>
            );
        case 3:
        case 13:
        case 20:
        case 21:
        case 30:
        case 31:
            return (
                <Badge color="success">
                    {orderStatusStr}
                </Badge>
            );
        case 4:
        case 8:
        case 14:
        case 45:
        case 46:
            return (
                <Badge color="info">
                    {orderStatusStr}
                </Badge>
            );
        case 7:
        case 9:
            return (
                <Badge color="primary">
                    {orderStatusStr}
                </Badge>
            );
        default:
            return;
    }
};

export const getOrderType = (orderType, appType) => {
    if (orderType === 0) {
        return 'Delivery';
    } else if (orderType === 1) {
        return 'Pickup';
    } else if (appType === 2) {
        return 'Dine-in POS';
    } else if (orderType === 2) {
        return 'Dine-in Online';
    } else {
        return 'N/A';
    }
};

const renderRows = (logs, onLoadLogDetail) => {
    logs = logs || [];
    return logs.map(log => {
        let info = {};
        try {
            if (log.info) {
                info = JSON.parse(log.info);
            }
        }
        catch (e) {
            console.log(e);
        }

        return (
            <tr key={log.requestID}>
                <td className="h3Class">{log.orderCode} </td>
                <td> {getOrderType(parseInt(log.orderType))}</td>
                <td> {getBadge(parseInt(log.orderStatus))} </td>
                <td>{info.printerID || ''} </td>
                <td>{info.printerName || ''} </td>
                <td> {showPrintingType(log.printingType)} </td>
                <td> {showDateTimeWithTimezone(log.modifiedDate)} </td>
                <td> {getPrintingStatus(parseInt(log.printingStatus))} </td>
                {/* <td> {log.desc} </td>
                <td> {log.message} </td> */}
                <td>
                    <Button size="sm" onClick={() => onLoadLogDetail(log.orderID, log.requestID)}>See Log Detail</Button>
                </td>
            </tr>
        );
    });
}

const ListOrderOld = (tableDataOrder, onLoadLogDetail, onBackPage) => {
    return (
        <Container>
            <Row>
                <Col>
                    <Button className="float-left" color="info" onClick={() => onBackPage()}>Back</Button>
                </Col>
            </Row>
            <h2 className="h2Class">Print Orders Logs</h2>
            <Table striped>
                <thead>
                    <tr>
                        <th>Order Code</th>
                        <th>Order Type</th>
                        <th>Order Status</th>
                        <th>Printer ID</th>
                        <th>Printer Name</th>
                        <th width="25%">Printing Type</th>
                        <th>Modified Time</th>
                        <th>Printing Status</th>
                        {/* <th>Description</th>
                    <th>Message</th> */}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>{renderRows(tableDataOrder, onLoadLogDetail)}</tbody>
            </Table>
        </Container>
    );
};

const ListOrder = (tableDataOrder, onLoadLogDetail, onBackPage) => {
    const data = Array.from(new Set(tableDataOrder))
        .map(log => {
            let info = {};
            try {
                if (log.info) {
                    info = JSON.parse(log.info);
                }
            }
            catch (e) {
                console.log(e);
            }
            return {
                orderID: log.orderID,
                requestID: log.requestID,
                orderCode: log.orderCode,
                orderType: getOrderType(parseInt(log.orderType)),
                orderStatus: parseInt(log.orderStatus),
                printerID: info.printerID || '',
                printerName: info.printerName || '',
                printingType: showPrintingType(log.printingType),
                modifiedDate: showDateTimeWithTimezone(log.modifiedDate),
                printingStatus: parseInt(log.printingStatus)
            };
        });
    data.sort((a, b) => (a.modifiedDate < b.modifiedDate) ? 1 : ((b.modifiedDate < a.modifiedDate) ? -1 : 0));

    const columns = [
        {
            name: 'Order Code',
            selector: 'orderCode',
            center: true,
        },
        {
            name: 'Order Type',
            selector: 'orderType',
            center: true,

        },
        {
            name: 'Order Status',
            selector: 'orderStatus',
            sortable: true,
            center: true,
            cell: row => getBadge(row.orderStatus)
        },
        {
            name: 'Printer ID',
            selector: 'printerID',
            sortable: true,
            center: true,
        },
        {
            name: 'Printer Name',
            selector: 'printerName',
            sortable: true,
            center: true,
        },
        {
            name: 'Printing Type',
            selector: 'printingType',
            sortable: true,
            center: true,
        },
        {
            name: 'Modified Date',
            selector: 'modifiedDate',
            sortable: true,
            center: true,
        },
        {
            name: 'Printing Status',
            selector: 'printingStatus',
            sortable: true,
            center: true,
            cell: row => getPrintingStatus(row.printingStatus)
        },
        {
            name: 'Action',
            center: true,
            cell: row => <Button size="sm" onClick={() => onLoadLogDetail(row.orderID, row.requestID)}>See Log Detail</Button>,
            button: true
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px',
                fontSize: '16px',
                backgroundColor: '#f6f6f6',
            }
        },
        headCells: {
            style: {
                fontSize: '16px',
                background: '#6c757d',
                color: '#ffffff',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
    };

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Button className="float-left" color="info" onClick={() => onBackPage()}>Back</Button>
                </Col>
            </Row>
            <h2 className="h2Class">Print Orders Logs</h2>
            <DataTable
                data={data}
                columns={columns}
                pagination={true}
                customStyles={customStyles}
                keyField='requestID'
                highlightOnHover={true}
                noHeader={true}
            />
        </Container>
    )
};

export default ListOrder;
