import React, { Component } from "react";
import "./App.css";
import { Header } from "./global/header";
import { Switch, Route } from "react-router-dom";

import Home from "./components/Home";
import MenuAvailabilityToday from "./components/MenuAvailabilityToday";
import MenuAvailabilityUntilNotice from "./components/MenuAvailabilityUntilNotice";
import MenuAvailabilityCustom from "./components/MenuAvailabilityCustom";
import MerchantDomain from "./components/MerchantDomain";
// import PlaceOrder from "./components/PlaceOrder";
// import UpdatePredicted from "./components/UpdatePredicted";
// import Kitchen from "./components/Kitchen";
import RealtimeOrder from "./components/RealtimeOrder";
import JobOrderPrintingLog from "./components/JobOrderPrintingLog";
import JobPOSOrderPrintingLog from "./components/JobPOSOrderPrintingLog";
import PrintingOrders from "./components/PrintingOrders";
import PrintingPOSOrders from "./components/PrintingPOSOrders";
import LoginPage from "./components/LoginPage";
import PrintingOrderLogDetail from "./components/PrintingOrderLogDetail";
import PrintingPOSOrderLogDetail from "./components/PrintingPOSOrderLogDetail";
import MenuAvailabilityDetail from "./components/MenuAvailabilityDetail";
import Merchant from './components/merchantPanel/merchant';
import Outlet from './components/merchantPanel/outlet'; 
import Station from './components/merchantPanel/station';
import DeliveryTracking from './components/deliveryOrder/deliveryTracking';
import LogMerchant from './components/deviceLogs/merchant';
import PrinterSettingLogs from './components/deviceLogs/printerSettingLogs';
import PrintoutSettingLogs from './components/deviceLogs/printoutSettingLogs';
import PrinterSettingLogDetails from './components/deviceLogs/printerSettingLogDetails';
import PrintoutSettingLogDetails from './components/deviceLogs/printoutSettingLogDetails';

/*The <Route> component is the components part of React Router. Anywhere that you want to only render content based on the location’s pathname, you should use a <Route> element. */
/* The Route component expects a path prop, which is a string that describes the pathname that the route matches */
/* The <Switch> will iterate over routes and only render the first one that matches the current pathname */

class App extends Component {
    render() {
        return (
            <div className="App">
                <Header />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/home" component={Home} />
                    <Route path="/maToday" component={MenuAvailabilityToday} />
                    <Route path="/maUntilNotice" component={MenuAvailabilityUntilNotice} />
                    <Route path="/maCustom" component={MenuAvailabilityCustom} />
                    {/*<Route path="/placeOrder" component={PlaceOrder} />*/}
                    {/*<Route path="/kitchen" component={Kitchen} />*/}
                    <Route path="/realtimeOrder" component={RealtimeOrder} />
                    <Route path="/printingOrders" component={PrintingOrders} />
                    <Route path="/printingPOSOrders" component={PrintingPOSOrders} />
                    <Route path="/jobOrderPrintingLogs" component={JobOrderPrintingLog} />
                    <Route path="/jobOrderPrintingLogs/:id" component={JobOrderPrintingLog} />

                    <Route path="/jobPOSOrderPrintingLogs" component={JobPOSOrderPrintingLog} />
                    <Route path="/jobPOSOrderPrintingLogs/:id" component={JobPOSOrderPrintingLog} />

                    <Route path="/printingOrderLogDetail/:id" component={PrintingOrderLogDetail} />
                    <Route path="/printingPOSOrderLogDetail/:id" component={PrintingPOSOrderLogDetail} />
                    <Route path="/merchantDomains" component={MerchantDomain} />
                    <Route path="/menuAvailabilityDetail/:type/:id" component={MenuAvailabilityDetail} />
                    <Route path="/merchant" component={Merchant} />
                    <Route path="/outlet/:bucket" component={Outlet} />
                    <Route path="/station/:bucket/:branchIndex" component={Station} />
                    <Route path="/deliveryTracking" component={DeliveryTracking} />
                    <Route path="/LogMerchant" component={LogMerchant} />
                    <Route path="/printerSettingLogs/:bucket" component={PrinterSettingLogs} />
                    <Route path="/printoutSettingLogs/:bucket" component={PrintoutSettingLogs} />
                    <Route path="/printerSettingLogDetails/:bucket/:date/:request" component={PrinterSettingLogDetails} />
                    <Route path="/printoutSettingLogDetails/:bucket/:date/:request" component={PrintoutSettingLogDetails} />
            </Switch>
            </div>
        );
    }
}

export default App;
