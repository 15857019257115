import React from "react";
import ReactDOM   from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
/* Using BrowserRouter to handle dynamic requests from Express Server */
import "bootstrap/dist/css/bootstrap.min.css";
import configureStore from "./store";
import Root from './components/Root';

const store = configureStore();

/* Router components expects to only contain one child component that's why combining everything in App and importing the same */
ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
