import React, { useState, useEffect } from "react";
import { Container, Table, Badge, Button, Row, Col } from "reactstrap";
import { checkTokenExpire } from '../services/userService';
import { database } from "../db/firebase";
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { formatDateTime } from "../helpers/utils";

//type= 0 Custom, 1 today, 2 Until further notice= 
const MenuAvailabilityDetail = ({ ...props }) => {
    const { id, type } = useParams();

    const date = '' + JSON.parse(localStorage.getItem('date'));
    if (date === null || date === '') {
        props.history.push('../../maToday');
    }
    const [data, setData] = useState({});
    const [ref] = useState(type + '/' + date + "/" + id);

    useEffect(() => {
        const fetchData = async () => {
            checkTokenExpire(() => props.history.push('../../login'));
            const value = await fetchDataFromFireBase("value", ref);
            setData(value);
        };

        fetchData();
    }, []);

    const fetchDataFromFireBase = async (e, ref) => {
        let snapshot = await database.ref(ref).once(e);
        let data = snapshot.val();
        if (data) {
            return data;
        } else {
            return {};
        }
    };

    const onBackPage = () => {
        let lgth = date.length;
        let dateConvert = '';
        for (let i = 0; i < lgth; i++) {
            if (i === 4 || i === 6) {
                dateConvert += '-';
            }
            dateConvert += date.charAt(i);
        }

        props.history.push({
            pathname: '../../' + type.replaceAll('-', ''),
            state: {
                currentDate: dateConvert//dateFormat(dateConvert, "mm-dd-yyyy"),
            },
        });
    }

    const SubTableProduct = products => {
        return products.map(product => {
            return (
                <tr key={product.docId}>
                    <td> {product.docId}</td>
                    <td> {ReactHtmlParser(product.title)} </td>
                </tr>
            );
        });
    }

    const renderRows = (data) => {
        const logOrderArray = Object.entries(data);
        let display = false;
        return logOrderArray.map(([key, value]) => {
            switch (key) {
                case "token": {
                    break;
                }
                case "status": {
                    let statusMessage = 'Fail';
                    let statusColor = 'danger';
                    if (value === 0) {
                        statusMessage = 'Waiting';
                        statusColor = 'warning';
                    }
                    else if (value === 1) {
                        statusMessage = 'Success';
                        statusColor = 'success';
                    }
                    value = (<Badge color={statusColor}>{statusMessage}</Badge>);
                    break;
                }
                case "groupName": {
                    value = ReactHtmlParser(value)
                    break;
                }
                case "modifiedDate": {
                    value = value ? formatDateTime(new Date(value)) : formatDateTime(new Date(null));
                    break;
                }
                case "createdDate": {
                    value = value ? formatDateTime(new Date(value + 'Z')) : formatDateTime(new Date(null));
                    break;
                }
                case "display": {
                    display = value;
                    break;
                }
                case "isModifierItem": {
                    value = value ? "Modifier" : "Product"
                    break;
                }
                case "menuTypeDashboard": {
                    value = display === false && value !== "Available" ? ReactHtmlParser(`<p style="text-decoration: line-through;">${value}</p>`) : value;
                    break;
                }
                default:
                    break;

            }

            if (key === "products" || key === "modifiers" || key === "maids") {
                return (
                    <tr key={key}>
                        <td className="h3Class" style={{ fontWeight: "bold" }}>{key} </td>
                        <td>
                            <Table striped>
                                <thead style={{background: '#6c757d', color: '#ffffff'}}>
                                    <tr>
                                        <th style={{width: "50%"}}>DocId</th>
                                        <th>Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {SubTableProduct(value)}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                );
            }
            else if (key !== "token" && key !== "productids" && key !== "modifierids" && key !== "display") {
                return (
                    <tr key={key}>
                        <td className="h3Class" style={{ fontWeight: "bold" }}>{key} </td>
                        <td>{value} </td>
                    </tr>
                );
            }
        });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Button className="float-left" color="info" onClick={() => onBackPage()}>Back</Button>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <h2 className="h2Class">Menu Availability Detail</h2>
                    <Table striped>
                        <tbody>{renderRows(data)}</tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default MenuAvailabilityDetail;