import React from "react";
import { Container, Table, Badge, Button, Row, Col } from "reactstrap";
import { showPrintingType, showDateTimeWithTimezone } from "./utils";

const arrayOrderStatus = [
    { orderStatus: 1, status: "New Order" }, //NewOrder
    { orderStatus: 2, status: "Confirmed" }, //ConfirmForDelivery
    { orderStatus: 3, status: "Delivering" }, //Delivering
    { orderStatus: 4, status: "Delivered" }, //Delivered
    { orderStatus: 5, status: "Confirmed" }, //ConfirmForCollection
    { orderStatus: 6, status: "" }, //Received
    { orderStatus: 7, status: "Rejected" }, //Canceled
    { orderStatus: 8, status: "Collected" }, //Collected
    { orderStatus: 9, status: "Refunded" }, //Refunded
    { orderStatus: 10, status: "Amended" }, //Amend
    { orderStatus: 11, status: "New Order" }, //SelfOrderingWaiting
    { orderStatus: 12, status: "Confirmed" }, //SelfOrderingConfirm
    { orderStatus: 13, status: "Serving" }, //SelfOrderingServing
    { orderStatus: 14, status: "Completed" }, //SelfOrderingDone
    { orderStatus: 15, status: "Amended" }, //SelfOrderingAmend
    { orderStatus: 17, status: "Sent to Kitchen" }, //SelfOrderingSenttoKitchen
    { orderStatus: 20, status: "In Preparation" }, //SendToKitchen
    { orderStatus: 21, status: "Ready For Collection" }, //ReadyForCollection
    { orderStatus: 30, status: "In Preparation" }, //SentToKitchenDelivery
    { orderStatus: 31, status: "Ready For Delivery" }, //ReadyForDelivery
    { orderStatus: 41, status: "Ordering" },
    { orderStatus: 42, status: "Bill Pending" },
    { orderStatus: 43, status: "Bill Now" },
    { orderStatus: 44, status: "Billing" },
    { orderStatus: 45, status: "Paid" },
    { orderStatus: 46, status: "Reserved" }
];

export const getOrderStatus = orderStatus => {
    var index = arrayOrderStatus.findIndex(o => o.orderStatus === orderStatus);
    if (index > -1) {
        return arrayOrderStatus[index].status;
    }
    return "N/A";
};

export const getPrintingStatus = (printingStatus) => {
    var printingStatusStr = printingStatus === 1 ? "Success" : printingStatus === 0 ? "Fail" : "N/A";
    switch (printingStatus) {
        case 0:
            return (
                <Badge color="danger">
                    {printingStatusStr}
                </Badge>
            );
            break;
        case 1:
            return (
                <Badge color="success">
                    {printingStatusStr}
                </Badge>
            );
            break;
        case 2:
            return (
                <Badge color="light">
                    {printingStatusStr}
                </Badge>
            );
            break;
        default:
            return;
    }
};

export const getBadge = (orderStatus) => {
    var orderStatusStr = getOrderStatus(orderStatus);
    switch (orderStatus) {
        case 1:
        case 11:
        case 41:
        case 17:
            return (
                <Badge color="danger">
                    {orderStatusStr}
                </Badge>
            );
        case 2:
        case 5:
        case 12:
        case 42:
            return (
                <Badge color="warning">
                    {orderStatusStr}
                </Badge>
            );
        case 3:
        case 13:
        case 20:
        case 21:
        case 30:
        case 31:
            return (
                <Badge color="success">
                    {orderStatusStr}
                </Badge>
            );
        case 4:
        case 8:
        case 14:
        case 45:
        case 46:
            return (
                <Badge color="info">
                    {orderStatusStr}
                </Badge>
            );
        case 7:
        case 9:
            return (
                <Badge color="primary">
                    {orderStatusStr}
                </Badge>
            );
        default:
            return;
    }
};

export const getOrderType = (orderType, appType) => {
    if (orderType === 0) {
        return 'Delivery';
    } else if (orderType === 1) {
        return 'Pickup';
    } else if (appType === 2) {
        return 'Dine-in POS';
    } else if (orderType === 2) {
        return 'POS';
    } else {
        return 'N/A';
    }
};

const renderRows = (logOrders, infoLogDetail) => {
    return logOrders.map(log => {
        const logOrderArray = Object.entries(log);
        return logOrderArray.sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) => {
            switch (key) {
                case "orderType": {
                    value = getOrderType(parseInt(value));
                    break;
                }
                case "orderStatus": {
                    value = getBadge(parseInt(value));
                    break;
                }
                case "printingType": {
                    value = showPrintingType(value)
                    break;
                }
                case "printingStatus": {
                    value = getPrintingStatus(parseInt(value));
                    break;
                }
                case "modifiedDate": {
                    value = showDateTimeWithTimezone(value);//dateFormat(value, "mm-dd-yyyy hh:mm:ss");
                    break;
                }
                case "createdDate": {
                    value = showDateTimeWithTimezone(value);//dateFormat(value, "mm-dd-yyyy hh:mm:ss");
                    break;
                }
                case "isManualPrintting": {
                    console.log('isManualPrintting', value)
                    if (value === null || value === undefined || value === '') {
                        value = "{Empty}";
                    }
                    break;
                }
                case "isReprint": {
                    if (value === 0 || value === false || value === 'false') {
                        value = "False";
                    }
                    else if (value === 1 || value === true || value === 'true') {
                        value = "True";
                    }
                    else {
                        value = "{Empty}";
                    }
                    break;
                }
                case "isNotDisplayItemChit": {
                    if (value === 0 || value === false || value === 'false') {
                        value = "False";
                    }
                    else if (value === 1 || value === true || value === 'true') {
                        value = "True";
                    }
                    else {
                        value = "{Empty}";
                    }
                    break;
                }
                default:
                    break;

            }

            if (key === "info" || key === "arrayProduct") {
                // return (
                //     <tr key={key}>
                //         <td className="h3Class" style={{fontWeight: "bold"}}>{key} </td>
                //         <td>
                //             <Table striped>
                //                 <tbody>

                //                     {renderInfoRows(infoLogDetail)}
                //                 </tbody>
                //             </Table>
                //         </td>
                //     </tr>
                // );
            }
            else {
                return (
                    <tr key={key}>
                        <td className="h3Class" style={{ fontWeight: "bold", textAlign: "left" }}>{key}: </td>
                        <td style={{ textAlign: "left" }}>{value} </td>
                    </tr>
                );
            }
        });
    });
}

const renderInfoRows = infos => {
    const infosArray = Object.entries(infos);
    if (infosArray.length > 0) {
        return infosArray.sort((a, b) => b[0].localeCompare(a[0])).map(([key, value]) => {
            switch (key) {
                case "isPrintOpenPriceOrderChit":
                case "isPrintOpenPriceReceipt":
                case "isShowMemberStatus":
                    if (value === 0 || value === false) {
                        value = "False";
                    }
                    else if (value === 1 || value === true) {
                        value = "True";
                    }
                    else {
                        value = "{Empty}";
                    }
                    break;
                case "isBigSizeDescriptionOfOrder":
                case "isBigSizeModifierReceipt":
                case "isBigSizePackagingFeeReceipt":
                case "isBigSizeProductReceipt":
                case "isBigSizeRemarksOfItem":
                case "isBigSizeRemarksOfOrder":
                case "isBigSizeStationInfoKitchenChit":
                case "isBigSizeStationInfoReceipt":
                case "isBigMarginTopReceipt":
                case "isManualPrinting":
                case "isHoldItemSentToKitchen":
                case "isBigMarginTopReceipt":
                case "isBigTopMarginInvoice":
                case "isBigTopMarginChit":
                case "isBigSizeRemarkOrder":
                case "isBigSizeRemarkItem":
                case "noOfPaxInput":
                case "kQueueSystemStatus":
                case "isShowPaxNumber":
                case "isReflectChangesInChecks":
                case "isPrintRewardReminder":
                case "isPrintFooterDate":
                case "isPrimary":
                case "isOpenCashDrawer":
                case "isOnFireLine":
                case "isBigSizeKitchenRemark":
                case "isBigSizeDescriptionItem":
                case "internetOn":
                case "combineSingleOrderChits":
                case "autoProceedCashier":
                case "autoPrint":
                case "autoCloseCheck":
                case "autoClearStation":
                case "seperateItemSingleOrderChits":
                case "applyAllProducts":
                case "isNoBoxUpPrintFormat":
                case "isNotManualFireLine":
                case "isBigMarginTopChit":
                    if (value === 0 || value === false) {
                        value = "False";
                    }
                    else if (value === 1 || value === true) {
                        value = "True";
                    }
                    else {
                        value = "{Empty}";
                    }
                    break;
                case "productIDsAvailable":
                case "categoryIDsAvailable":
                    const renderList = value.map((item, index) =>
                        <div key={index}>{item};</div>
                    );
                    value = renderList;
                    break;
                case "generalSetting":
                case "fireLineSetting":
                    value = JSON.stringify(value)
                    break;
                default:
                    break;
            }

            return (
                <tr key={key}>
                    <td className="h3Class" style={{ fontWeight: "bold", textAlign: "left" }}>{key}: </td>
                    <td style={{ textAlign: "left" }}>{value}</td>
                </tr>
            );
        });
    }
    else {
        return (
            <tr key={'key'}>
                <td className="h3Class" style={{ fontWeight: "bold" }}>{"{Empty}"} </td>
            </tr>
        );
    }
}

const renderArrayProductRows = arrayProduct => {
    if (arrayProduct && arrayProduct.length > 0) {
        return arrayProduct.map((product, index) => {
            return (
                <tr key={index}>
                    <td className="h3Class">{product.nameForPrint}</td>
                    <td className="h3Class">{product.displayViewName}</td>
                    <td className="h3Class">{product.fireCourseName}</td>
                    <td className="h3Class">{product.categoryName}</td>
                    <td className="h3Class">{product.productIDsAvailable}</td>
                    <td className="h3Class">{product.isOpenPrice?.toString()}</td>
                    <td className="h3Class">{product.productQuantity}</td>
                    <td className="h3Class">{product.isProductNotPrint?.toString()}</td>
                </tr>
            );
        });
    }
}

const OrderLogDetail = (tableDataOrder, onBackPage) => {
    let infoLogDetail = {};
    let arrayProductLogDetail = [];
    try {
        const valueLogDetail = tableDataOrder[0];
        if (valueLogDetail && valueLogDetail.info) {
            infoLogDetail = JSON.parse(valueLogDetail.info);
        }

        if (valueLogDetail && valueLogDetail.arrayProduct) {
            if (typeof valueLogDetail.arrayProduct === "string") {
                arrayProductLogDetail = [...JSON.parse(valueLogDetail.arrayProduct)];
            }
            else {
                arrayProductLogDetail = [...valueLogDetail.arrayProduct];//JSON.parse(valueLogDetail.arrayProduct);
            }
        }
    }
    catch (e) {
        console.log(e);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Button className="float-left" color="info" onClick={() => onBackPage()}>Back</Button>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md={{ size: 6 }}>
                    <h2 className="h2Class">Print Orders Log Detail</h2>
                    <Table striped>
                        <tbody>{renderRows(tableDataOrder, infoLogDetail)}</tbody>
                    </Table>
                </Col>
                <Col sm="12" md={{ size: 6 }}>
                    <h2 className="h2Class">Print Info</h2>
                    <Table striped>
                        <tbody>{renderInfoRows(infoLogDetail)}</tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <h2 className="h2Class">Array Product Log Printout</h2>
                    <Table striped>
                        <thead>
                            <tr className="h3Class" style={{ fontWeight: "bold" }}>
                                <td>Name For Print</td>
                                <td>Display View Name</td>
                                <td>Fire Course Name</td>
                                <td>Category Name</td>
                                <td>ProductIDs Available</td>
                                <td>Is Open Price</td>
                                <td>Product Quantity</td>
                                <td>Is Product Not Print</td>
                            </tr>
                        </thead>
                        <tbody>{renderArrayProductRows(arrayProductLogDetail)}</tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default OrderLogDetail;
