import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Spinner, FormFeedback, Card } from "reactstrap";
import * as userService from '../services/userService';

const initialFieldValues = {
  username: '',
  password: '',
}

const LoginPage = ({ classes, ...props }) => {

  const onHistory = () => {
    props.history.push('/');
  }

  useEffect(() => {
    //props.checkAuthentication(onHistory, true);
    userService.checkTokenExpire(onHistory, true);
  }, [])//componentDidMount
  

  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});
  const [spinnerStyle, setSpinnerStyle] =  useState({ width: '1.5rem', height: '1.5rem', display: "none" });

  const validate = (fieldValues = values) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let temp = { ...errors }
    if('username' in fieldValues)
      temp.username = re.test(fieldValues.username) ? false : true;

    if('password' in fieldValues)
      temp.password = fieldValues.password ? false : true;

    setErrors({
      ...temp
    })

    if (fieldValues === values)
      return Object.values(temp).every(x => x === false)
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (validate()) {
      setSpinnerStyle({ width: '1.5rem', height: '1.5rem', display: "inline-block" });
      const onSuccess = () => {
        setSpinnerStyle({ width: '1.5rem', height: '1.5rem', display: "none" });
        //onHistory();
        window.location.href = '/';
      }

      const onFail = () => {
        setSpinnerStyle({ width: '1.5rem', height: '1.5rem', display: "none" });
        window.alert("Login Fail");
      }

      //props.loginAuthentication(values, onSuccess, onFail);
      userService.login(values, onSuccess, onFail);
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    const fieldValue = { [name]: value }
    setValues({
        ...values,
        ...fieldValue
    })
    
    validate(fieldValue)
  }

  return (
    <Container className="themed-container mt-5">
      <h2 className="h2Class">Login Page</h2>
      <Card body outline color="primary">
        <Form autoComplete="off" className="text-md-left" onSubmit={handleSubmit}>
          <Row form>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <FormGroup>
                <Label className="font-weight-bold" for="username">Email Address</Label>
                <Input 
                  id="username"
                  name="username" 
                  type="text" 
                  value={values.username}
                  onChange={handleInputChange}
                  valid={errors.username !== undefined ? !errors.username : false }
                  invalid={errors.username !== undefined ? errors.username : false }
                  />
                <FormFeedback>This field is required</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <FormGroup>
                <Label className="font-weight-bold" for="password">Password</Label>
                <Input 
                  id="password"
                  name="password" 
                  type="password" 
                  value={values.password} 
                  onChange={handleInputChange}
                  valid={errors.password !== undefined ? !errors.password : false }
                  invalid={errors.password !== undefined ? errors.password : false }
                />
                <FormFeedback>This field is required</FormFeedback>
              </FormGroup>
            </Col>
            <Col className="text-md-center" sm="12" md={{ size: 6, offset: 3 }}>
              <FormGroup check row>
                <Button className="font-weight-bold" outline color="primary" size="lg">Login
                  <Spinner className="ml-3" type="border" style={spinnerStyle} size="sm" color="warning" />{' '}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Card>
    </Container>
  );
}

// const mapStateToProps = state => {
//   return {}
// }

// const mapActionToProps = dispatch => {
//   return {
//     checkAuthentication: userService.checkTokenExpire,
//     loginAuthentication: userService.login
//   };
// }

// export default connect(mapStateToProps, mapActionToProps)(LoginPage);

export default LoginPage;