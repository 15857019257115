
import {
    FILTER_DATA,
    INITIAL_PRINTOUT_SETTING_LOG_LIST
} from "../actions/types";

const initialState = {
    logs: [],
    logsFilters: [],
};

const filterAllData = logs => {
    return {
        logs: logs
            .sort(function (a, b) {
                const nameA = a.requestID.toUpperCase(); // ignore upper and lowercase
                const nameB = b.requestID.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            .slice(0, 100),
    };
};

const printoutSettingLogsListReducer = (state = initialState, action) => {
    console.log(action.type);
    switch (action.type) {
        case FILTER_DATA:
            return {
                ...state,
                logs: action.payload.logs
            };
        case INITIAL_PRINTOUT_SETTING_LOG_LIST:
            const {logs} = filterAllData(
                action.payload.logs
            );
            return {
                ...state,
                logs: logs,
                logsFilters: action.payload.logsFilters
            };

        default:
            return state;
    }
};

export default printoutSettingLogsListReducer;
