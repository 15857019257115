import handleResponse from '../helpers/handle-response';

import { getzAPI } from '../env';

//const baseUrl = 'http://localhost/Getz.API';
const baseUrl = getzAPI;

export const reflectOnlineStore = (obj, onSuccess, onFail) => {
    const model = {
        docId: obj.menuAvailabilityId,
        userID: obj.employeeID,
        userOwner: obj.email,
        title: obj.title,
        requestId: obj.id,
        totalrequest: obj.totalrequest,
        bucket: obj.bucket,
        modifiedDate: obj.modifiedDate,
        isModifierItem: obj.isModifierItem,
        groupName: obj.groupName,
        from: obj.from,
        display: obj.display,
        validityPeriodType: obj.type,
        businessName: obj.businessName,
        outletName: obj.outletName,
        menuType: obj.menuType,
        groups: [],//obj.groups,
        productids: obj.productids,
        modifierids: obj.modifierids,
        menuAvailabilityIds: obj.menuAvailabilityIds,
        menuAvailabilityTitles: obj.menuAvailabilityTitles,
        isShowGroup: false
    };
    // if(obj.groups && obj.groups.length > 0){
    //     model.isShowGroup = (obj.groups[0].isShowGroup === true);
    // }
    if (obj.groups && obj.groups.length > 0) {
        var itemG = {};
        itemG.isShowGroup = obj.groups[0].isShowGroup;
        model.groups.push(itemG);
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',//'application/x-www-form-urlencoded',//
            'Authorization': `Bearer ${obj.token}`,
        },
        body: JSON.stringify(model)
    };

    return fetch(`${baseUrl}/api/ReflectOnlineStore`, requestOptions)
        .then(handleResponse)
        .then(dataAuth => {
            console.log(dataAuth);
            if (dataAuth.status === 1) {//dataAuth.message === "Success" && 
                onSuccess();
            }
            else {
                onFail();
            }
            return dataAuth;
        })
        .catch(err => {
            console.log(err);
            onFail();
        });
}
