import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Button, Form, InputGroup, Input, InputGroupAddon, Badge, Spinner } from "reactstrap";
import ReactHtmlParser from 'react-html-parser';
import * as merchantService from '../services/merchantService';
import { checkTokenExpire } from '../services/userService';
import { removeVietnameseTones } from "../helpers/utils";

const columns = [
	{
		name: 'Merchant',
		selector: 'businessName',
		sortable: true,
		center: true,
		cell: row => <div title={row.MerchantName}>{row.MerchantName}
			<input type='hidden' name='MerchantId' value={row.merchantID} />
		</div>,
	},
	{
		name: 'Getz Sub Domain',
		sortable: true,
		selector: 'GetzSubDomain',
		center: true,
		cell: row => ReactHtmlParser(row.GetzSubDomain)
	},
	{
		name: 'Primary Domain',
		sortable: true,
		selector: 'PrimaryDomain',
		center: true,
		cell: row => row.PrimaryDomain
	},
	{
		name: 'Ping',
		sortable: true,
		selector: 'PingDomain',
		center: true,
	},
	{
		name: 'Is Secured?',
		sortable: true,
		selector: 'IsSecured',
		center: true,
		width: "150px",
		cell: row => {
			if (row.IsSecured) {
				return (
					<Badge color="success">Yes</Badge>
				)
			}
			else {
				return (
					<Badge color="danger">No</Badge>
				)
			}
		}
	}
];

const customStyles = {
	rows: {
		style: {
			minHeight: '72px',
			fontSize: '16px',
			backgroundColor: '#f6f6f6',
		}
	},
	headCells: {
		style: {
			fontSize: '16px',
			background: '#6c757d',
			color: '#ffffff',
		},
	},
	cells: {
		style: {
		},
	},
};

const MerchantDomain = ({ ...props }) => {
	const [dataRoot, setDataRoot] = useState([]);
	const [data, setData] = useState([]);
	const [search, setSearch] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		checkTokenExpire(() => props.history.push('../../login'));
		fetchData();
	}, []);

	const onHistory = () => {
	}

	const fetchData = async () => {
		const value = await merchantService.getListPrimaryDomain(onHistory);
		if(value) {
			setDataRoot([...value]);
			setData([...value]);
		}
		
		setLoading(false);
	};

	const handleInputChange = e => {
		const { name, value } = e.target;
		switch (name) {
			case 'search':
				setSearch(value.trim());
				break;

			default:
				break;
		}
	}

	const handleSubmit = e => {
		e.preventDefault();

		if (search && search.length > 0) {
			const textSearch = removeVietnameseTones(search.toString());
			const filterData = dataRoot.filter(x => (x.MerchantName && x.MerchantName.match(textSearch))
				|| (x.PrimaryDomain && removeVietnameseTones(x.PrimaryDomain).match(textSearch))
			);

			setData(filterData);
		}
		else {
			setData(dataRoot);
		}
	}

	const renderFormFilters = () => {
		return (
			<Form body="true" autoComplete="off" onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', marginRight: '-15px' }}>
				<InputGroup>
					<Input
						name="search"
						placeholder="Search..."
						onChange={handleInputChange}
					/>
					<InputGroupAddon addonType="append">
						<Button color="success" type="submit">Search</Button>
					</InputGroupAddon>
				</InputGroup>
			</Form>
		)
	}

	return (
		<Container className="themed-container">
			<h2 className="h2Class">Merchant Domains Status</h2>
			<div className="mb-3">
				<Spinner color="primary" style={loading ? {margin: "0 auto", display: "block"} : {margin: "0 auto", display: "none"}} />
				<DataTable
					data={data}
					columns={columns}
					pagination={true}
					customStyles={customStyles}
					keyField='id'
					highlightOnHover={true}
					noHeader={true}
					subHeader={true}
					subHeaderComponent={renderFormFilters()}
					subHeaderAlign='right'
					paginationRowsPerPageOptions={[50, 100, 200, 500]}
					paginationPerPage={50}
					style={loading ? {display: "none"} : {display: "block"}}
				/>
			</div>
		</Container>
	)
}

export default MerchantDomain;
