import React, { useState, useEffect } from 'react';
import { Container, Card, CardTitle, CardText, Row, Col, CardHeader, CardBody, Input, Button, InputGroup, InputGroupAddon, Badge, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useParams, NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import * as merchantService from '../../services/merchantService';
import { checkTokenExpire } from '../../services/userService';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import AdjustIcon from '@material-ui/icons/Adjust';
import AlbumIcon from '@material-ui/icons/Album';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';
import { database } from "../../db/firebase";

import 'react-toastify/dist/ReactToastify.css';
import './station.css';

const Station = ({ ...props }) => {
    const { bucket, branchIndex } = useParams();

    const [data, setData] = useState([]);
    const [time, setTime] = useState();
    const [page, setPage] = useState(0);
    const [modal, setModal] = useState(false);
    const [stationLog, setStationLog] = useState([]);
    const currentDate = dateFormat(new Date(), "yyyymmdd");

    const pageNumber = 24;

    useEffect(() => {
        checkTokenExpire(() => props.history.push('../../login'));
        fetchData();
    }, []);

    const onHistory = () => { }

    const fetchData = async () => {
        const value = await merchantService.getListStation(onHistory, bucket, branchIndex);
        setPage(0);
        if (value) {
            setData(value);
        }
    };


    const revertOrderStation = async orderStation => {
        const dataRevert = {
            orderId: orderStation.orderId,
            stationStatusCode: orderStation.stationStatusCode,
            stationId: orderStation.stationId
        }
        const result = await merchantService.revertStation(dataRevert, onHistory);

        if (result) {
            toast.success('Revert successfully!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            toggle(orderStation.stationId, true);
            fetchData();
        }
        else {
            toast.success('Revert failed!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    const columns = [
        {
            name: 'Order Code',
            selector: 'orderCode',
            sortable: true,
            width: '220px',
            cell: row => <div title={row.orderCode}>
                #{row.orderCode}
                <input type="hidden" name="stationStatusCode" value={row.orderId || ''} />
            </div>,
        },
        {
            name: 'Number of pax',
            selector: 'totalPax',
            width: '150px',
            center: true,
            cell: row => <div title={row.totalPax}>{row.totalPax}</div>,
        },
        {
            name: 'Total',
            selector: 'total',
            width: '130px',
            center: true,
            cell: row => <div title={row.total}>SGD {row.total}</div>,
        },
        {
            name: 'Last Order Status ',
            selector: 'lastChangeDate',
            center: true,
            cell: row => <div title={row.orderStatusName}>
                {row.orderStatusName}
                <input type="hidden" name="orderStatusCode" value={row.orderStatusCode || ''} />
            </div>,
        },
        {
            name: 'Last POS Status',
            selector: 'deliveryPartner',
            center: true,
            cell: row => <div title={row.stationStatusName}>
                {row.stationStatusName}
                <input type="hidden" name="stationStatusCode" value={row.stationStatusCode || ''} />
            </div>,
        },
        {
            name: 'Last updated time',
            selector: 'lastChangeDate',
            center: true,
            cell: row => <div title={row.lastChangeDate}>{row.lastChangeDate}</div>,
        },
        {
            name: 'Revert',
            center: true,
            cell: row => (
                <FlipCameraAndroidIcon className='revert-icon' onClick={() => revertOrderStation(row)} />
            ),
            button: true
        }
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px',
                fontSize: '16px',
            }
        },
        headCells: {
            style: {
                fontSize: '16px',
            }
        }
    };

    const handleInputChange = e => {
        const { value } = e.target;
        setTime(value * 60000);
    }

    const handleIntervalReloadClick = () => {
        if (time && time > 0) {
            const interval = setInterval(() => {
                fetchData();
            }, time);

            return () => clearInterval(interval);
        }
    }

    const handlePagingClick = key => {
        setPage(key);
    }

    const renderPaging = () => {
        const datalength = data.length;
        const remainder = datalength % pageNumber;
        const totalPage = remainder === 0 ? Math.floor(datalength / pageNumber) : Math.floor(datalength / pageNumber) + 1;
        let pageCurrent = [];
        for (let i = 0; i < totalPage; i++) {
            if (i === page) {
                pageCurrent.push({
                    current: true,
                    key: i
                });
            }
            else {
                pageCurrent.push({
                    current: false,
                    key: i
                });
            }
        }

        if (pageCurrent.length > 1) {
            return pageCurrent.map(x => {
                if (x.current) {
                    return (
                        <AlbumIcon key={x.key} color="primary" style={{ cursor: 'pointer' }} onClick={() => handlePagingClick(x.key)} />
                    )
                }
                else {
                    return (
                        <AdjustIcon key={x.key} fontSize='small' style={{ cursor: 'pointer' }} onClick={() => handlePagingClick(x.key)} />
                    )
                }
            });
        }
    }

    const toggle = async (stationId, isRevert = false) => {
        if (stationId) {
            const ref = `station/${currentDate}/stationId:${stationId}`;
            const snapshot = await database.ref(ref).once("value");
            const data = snapshot.val();
            if (data) {
                setStationLog(Object.values(data));
            }
            else {
                setStationLog([]);
            }
        }
        else {
            setStationLog([]);
        }
        
        if (!isRevert) {
            setModal(!modal);
        }
    }

    const renderStationCard = () => {
        let minutes = 0, diffMs = 0;
        return data.slice(page * pageNumber, pageNumber * (page + 1)).map(station => {
            switch (station.statusOrdering) {
                case 0:
                    station.statusOrderingColor = 'available';
                    break;
                case 1:
                    station.statusOrderingColor = 'occupied';
                    break;
                case 2:
                    station.statusOrderingColor = 'ordering';
                    break;
                case 3:
                    station.statusOrderingColor = 'bill-pending';
                    break;
                case 4:
                    station.statusOrderingColor = 'bill-now';
                    break;
                case 5:
                    station.statusOrderingColor = 'billing';
                    break;
                default:
                    station.statusOrderingColor = 'paid';
                    break;
            }

            diffMs = new Date() - new Date(station.modifiedDate);
            minutes = Math.floor(diffMs / 86400000) * 1440 + Math.floor((diffMs % 86400000) / 3600000) * 60 + Math.round(((diffMs % 86400000) % 3600000) / 60000);

            return (
                <Col className='pb-4' key={station.docId}>
                    <Card outline className={"border-" + station.statusOrderingColor}>
                        <Input type='hidden' name='docId' value={station.docId} />
                        <CardHeader className={"bg-" + station.statusOrderingColor}>
                            <strong>{station.stationName}</strong>
                        </CardHeader>
                        <CardBody className={"bg-" + station.statusOrderingColor}>
                            <Input type='hidden' name='facilityTypeId' value={station.facilityTypeId} />
                            <CardTitle>
                                <PeopleAltIcon className='icon-title' />{station.totalPax !== 0 ? station.totalPax : '--'}&nbsp;/
                                <LoyaltyIcon className='icon-title' />--&nbsp;/
                                <DashboardIcon className='icon-title' />{station.stationSize}
                            </CardTitle>
                            <CardText className='time-minutes'>
                                <AccessTimeIcon fontSize="small" />&nbsp;{dateFormat(station.modifiedDate, "hh:MM TT")} - {minutes}
                            </CardText>
                            <CardText className='orderCode' hidden={station.statusOrdering === 0 ? "hidden" : ""}>
                                <span>{station.orderCode}</span>
                                <Input type='hidden' name='orderId' value={station.orderId || ''} />
                            </CardText>
                            <div className={'show-log bg-hover-' + station.statusOrderingColor}>
                                <Button onClick={() => toggle(station.docId)} className={"bg-hover-" + station.statusOrderingColor}>
                                    <DescriptionSharpIcon className='icon-title' /> Show log
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            );
        });
    }

    return (
        <div>
            <Container className="themed-container">
                <Row>
                    <Col sm='1'>
                        <NavLink exact to={`/outlet/${bucket}`}>
                            <Button>Back</Button>
                        </NavLink>
                    </Col>
                    <Col sm='10'>
                        <h2 className="h2Class">Station</h2>
                    </Col>
                    <Col sm='1'></Col>
                </Row>
                <Row form className='mb-3'>
                    <Col sm={12} md={{ size: 1, offset: 8 }}>
                        <Button color='primary' onClick={() => fetchData()}>Reload</Button>
                    </Col>
                    <Col sm={12} md={3}>
                        <InputGroup style={{ display: 'flex', alignItems: 'center', marginRight: '-15px' }}>
                            <Input
                                type='number'
                                name="search"
                                placeholder="Minutes"
                                defaultValue={time}
                                onChange={handleInputChange}
                            />
                            <InputGroupAddon addonType="append">
                                <Button color="success" onClick={handleIntervalReloadClick}>Reload</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className='box-station' xs="2" sm="2" md="6">
                    {renderStationCard()}
                </Row>
                <Row className='pb-5 text-center' style={{ display: 'block' }}>
                    {renderPaging()}
                </Row>
                <Row className='mb-3' style={{ color: '#ffffff' }}>
                    <Col style={{ backgroundColor: '#7AB362' }}>Available</Col>
                    <Col style={{ backgroundColor: '#FE8905' }}>Occupied</Col>
                    <Col style={{ backgroundColor: '#006BBD' }}>Ordering</Col>
                    <Col style={{ backgroundColor: '#FB0202' }}>BillPending</Col>
                    <Col style={{ backgroundColor: '#AE1800' }}>BillNow</Col>
                    <Col style={{ backgroundColor: '#FF578E' }}>Billing</Col>
                    <Col style={{ backgroundColor: '#B1B1B1' }}>Paid</Col>
                </Row>
            </Container>
            <Modal isOpen={modal} toggle={() => toggle()} size="xl">
                <ModalBody>
                    <DataTable
                        data={stationLog}
                        columns={columns}
                        customStyles={customStyles}
                        highlightOnHover={true}
                        noHeader={true}
                        subHeader={false}
                    //pagination={true}
                    // paginationRowsPerPageOptions={[15, 30, 50, 100]}
                    // paginationPerPage={15}
                    />
                </ModalBody>
                <ModalFooter style={{ justifyContent: 'center', paddingTop: '50px' }}>
                    <Button style={{ padding: '5px 50px' }} outline color="secondary" onClick={() => toggle()}>Close</Button>
                </ModalFooter>
            </Modal>
            <ToastContainer />
        </div>
    )
}

export default Station;
